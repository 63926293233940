import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import {
  Grid,
  Button,
  Autocomplete,
  TextField,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Switch,
  FormControlLabel,
  Typography,
  Chip,
  Paper,
  Tooltip,
  IconButton,
  FormHelperText,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  BUSINESS_TYPE,
  BUSINESS_DOMAIN,
  PROJECT_COUNT,
  ISO_CERTIFICATES,
  TYPES,
} from "config";
import * as Yup from "yup";
import { Formik } from "formik";

import { updateBusinessProfile } from "actions/businessActions";
import { getBusinessLabel } from "utils/constantUtils";

import { QuillEditor } from "components";
import CustomToolbar from "components/QuillCustomTollBar";
import axios from "utils/axios";

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    "& .ql-editor": {
      height: 200,
    },
  },
}));

function BusinessProfileDetailsForm({
  supplierId,
  businessName,
  profile,
  gstin,
  onSubmitSuccess,
  annualTurnOver,
  ...rest
}) {
  const classes = useStyles();
  const [tags, setTags] = React.useState([]);
  const [gstMessage, setGstMessage] = useState(false);
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
    // counter: {
    //   container: '#counter',
    //   unit: 'character'
    // }
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        businessName: businessName,
        businessEmail: profile ? profile?.businessEmail : "",
        gstin: gstin,
        annualTurnOver: annualTurnOver,
        businessMobile: profile ? profile?.businessMobile : "",
        contact: profile ? profile?.contact : "",
        businessType: profile ? profile?.businessType : "",
        businessHeadline: profile ? profile?.businessHeadline : "",
        description: profile ? profile?.description : "",
        businessCategory: profile ? profile?.businessCategory : [],
        establishYear: profile ? profile?.establishYear : "",
        domains: profile ? profile?.domains : [],
        projectCount: profile ? profile?.projectCount : "",
        certificates: profile ? profile?.certificates : [],
        websiteUrl: profile ? profile?.websiteUrl : "",
        supplierType: profile ? profile?.supplierType : "",
      }}
      validationSchema={Yup.object().shape({
        businessName: Yup.string().required("BusinessName is required"),

        businessEmail: Yup.string()
          .email()
          .max(80)
          .required("Please enter valid E-mail"),
        gstin: Yup.string()
          .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
            "Please Enter Valid GST Number"
          )
          .max(20)
          .min(10),
        businessMobile: Yup.string()
          .matches(
            /^[6-9][0-9]{9}/,
            "Please enter valid 10 digit mobile number"
          )
          .max(10)
          .min(10)
          .required("Please enter mobile number"),
        contact: Yup.string()
          .matches(
            /^([0-9]{11}$)|(^[6-9][0-9]{9}$)/,
            "Please enter valid landline number"
          )
          .max(11)
          .min(10),
        businessHeadline: Yup.string().min(2).max(100).required(),
        businessCategory: Yup.array()
          .of(Yup.string().required("Business Category is required"))
          .min(1, "At least one Business Category is required")
          .required("Business Category is required"),
        supplierType:
          selectedOption?.includes("Material Supplier")  &&
          Yup.string().required("Supplier type is required."),
        description: Yup.string()
          .min(2)
          .max(2000)
          .required("Description is required"),
        // description: Yup.string()
        // .test(
        //   "is-not-empty",
        //   "Description is required",
        //   (value) => !value.includes("<br>")
        // )
        // .min(2)
        // .max(2000)
        // .required(),
        businessType: Yup.string().min(2).required(),
        establishYear: Yup.string()
          .matches(/^[1-2][0-9]{3}/, "Please enter valid 4 digit year")
          .min(4)
          .max(4)
          .required(),
        websiteUrl: Yup.string().matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // console.log("$####", values);
        try {
          if (values.websiteUrl && !/^https?:\/\//i.test(values.websiteUrl)) {
            values.websiteUrl = `https://${values.websiteUrl}`;
          }
          if (values.gstin !== gstin && values?.gstin.trim() !== "") {
            // GST number has been updated, perform the API call to check its existence
            const res = await axios.get(
              `/api/supplier/check/gst/exist?gstNumber=${values.gstin}`
            );
            if (res.data.error === true) {
              // If GST number already exists, set error and return
              setGstMessage(true);
              return;
            }
          }
          await dispatch(updateBusinessProfile(supplierId, values));
          onSubmitSuccess();
        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        setFieldValue,
      }) => {
        const handleNestedAutocompleteChange = (event, newValue) => {
          setFieldValue("supplierType", newValue);
        };

        const handleAutocompleteChange = (event, newValue) => {
          if (!newValue.includes("Material Supplier")) {
            setFieldValue("supplierType", null);
          }
          setSelectedOption(newValue);

          setFieldValue("businessCategory", newValue);

          // Reset the selected supplier type when the business category changes
        };
        return (
          <Card>
            <CardHeader title="Business Profile" />
            <Divider />
            <CardContent>
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      id="businessName"
                      label="Company Display Name"
                      value={values.businessName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 120 }}
                      error={Boolean(
                        touched.businessName && errors.businessName
                      )}
                      helperText={touched.businessName && errors.businessName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      id="businessEmail"
                      type="email"
                      label="Company Email Address"
                      value={values.businessEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(
                        touched.businessEmail && errors.businessEmail
                      )}
                      helperText={
                        touched.businessEmail && errors.businessEmail
                          ? "Please enter valid E-mail"
                          : "** Visible on Business Profile. Receive notifications on leads & connections on it"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      error={
                        Boolean(touched.gstin && errors.gstin) || gstMessage
                      }
                      fullWidth
                      helperText={
                        (touched.gstin && errors.gstin) ||
                        (gstMessage &&
                          "This GST No. is already registered with us, Kindly enter new GST No.")
                      }
                      onFocus={() => setGstMessage(false)}
                      label="GSTIN"
                      name="gstin"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.gstin}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <InputLabel id="annual-select-label">
                        Annual Turnover
                      </InputLabel>
                      <Select
                        labelId="annual-select-label"
                        id="annualTurnOver"
                        name="annualTurnOver"
                        label="annualTurnOver"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.annualTurnOver}
                        error={errors.annualTurnOver}
                        helperText={errors.annualTurnOver}
                        required
                      >
                        <MenuItem value={"Rs. 0 to 40 lakhs"}>
                          Rs. 0 to 40 lakhs
                        </MenuItem>
                        <MenuItem value={"Rs. 40 lakhs to 1.5 Cr."}>
                          Rs. 40 lakhs to 1.5 Cr.
                        </MenuItem>
                        <MenuItem value={"Rs. 1.5 Cr. to 5 Cr."}>
                          Rs. 1.5 Cr. to 5 Cr.
                        </MenuItem>
                        <MenuItem value={"Rs. 5 Cr. to 25 Cr."}>
                          Rs. 5 Cr. to 25 Cr.
                        </MenuItem>
                        <MenuItem value={"Rs. 25 Cr. to 100 Cr. "}>
                          Rs. 25 Cr. to 100 Cr.
                        </MenuItem>
                        <MenuItem value={"Rs. 100 Cr. to 500 Cr."}>
                          Rs. 100 Cr. to 500 Cr.
                        </MenuItem>
                        <MenuItem value={"Rs. 500 Cr. and above"}>
                          Rs. 500 Cr. and above
                        </MenuItem>
                      </Select>
                      <Typography sx={{ fontSize: "10px" }}>
                        Note: We recommend to select a range from the dropdown
                        for your business to be filtered by the buyer
                      </Typography>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      fullWidth
                      required
                      id="businessMobile"
                      label="Company WhatsApp number"
                      value={values.businessMobile}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(
                        touched.businessMobile && errors.businessMobile
                      )}
                      helperText={
                        touched.businessMobile && errors.businessMobile
                          ? "Please enter valid mobile number"
                          : "** Not Visible on Business Profile but will be used for notifications only"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="contact"
                      label="Company Contact Number ( STD+No eg. 02278787878)"
                      value={values.contact}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.contact && errors.contact)}
                      helperText={
                        touched.contact && errors.contact
                          ? "Please enter valid landline number"
                          : "** It will be visible on business profile"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      multiple
                      value={values.businessCategory}
                      onChange={handleAutocompleteChange}
                      disablePortal
                      required
                      id="businessCategory"
                      options={TYPES}
                      isOptionEqualToValue={(option, value) => option === value}
                      error={Boolean(errors.businessCategory)}
                      helperText={errors.businessCategory}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Business Category"
                          error={Boolean(
                            touched.businessCategory && errors.businessCategory
                          )}
                          helperText={
                            touched.businessCategory && errors.businessCategory
                          }
                        />
                      )}
                    />
                  </Grid>
                  {isMdUp && <Grid item xs={0} md={6}></Grid>}
                  {(selectedOption?.includes("Material Supplier") ||
                    values?.businessCategory.includes("Material Supplier")) && (
                      <Grid
                        style={{ paddingTop: isMdUp ? "15px" : "15px" }}
                        item
                        xs={12}
                        md={6}
                      >
                        <Autocomplete
                          size="small"
                          value={values.supplierType}
                          onChange={handleNestedAutocompleteChange}
                          disablePortal
                          id="supplierType"
                          options={["Manufacturer", "Trader"]}
                          renderInput={(params) => (
                            <TextField
                              error={Boolean(
                                touched.supplierType && errors.supplierType
                              )}
                              helperText={
                                touched.supplierType && errors.supplierType
                                  ? "Please select valid supplier type"
                                  : ""
                              }
                              {...params}
                              label="Are you a Trader or Manufacturer ?"
                              placeholder="Supplier Type"
                            />
                          )}
                        />
                      </Grid>
                    )}
                  <Grid
                    style={{ paddingTop: "18px" }}
                    item
                    xs={12}
                    md={12}
                    sx={{ display: "flex" }}
                  >
                    <TextField
                      fullWidth
                      required
                      id="businessHeadline"
                      label="Business Headline"
                      value={values.businessHeadline}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      error={Boolean(
                        touched.businessHeadline && errors.businessHeadline
                      )}
                      helperText={
                        touched.businessHeadline && errors.businessHeadline
                          ? "** Visible on Business Profile. One liner for your business (Example: 16 Awards, 350 Happy Clients, 570 Projects Handled. Best in class Structural Engineering Consultants)"
                          : ""
                      }
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      required
                      id="description"
                      label="Business Description"
                      value={values.description}
                      multiline
                      rows={4}
                      onChange={handleChange}
                      inputProps={{ maxLength: 2000 }}
                      error={Boolean(touched.description && errors.description)}
                    />
                  </Grid> */}

                  <Grid item xs={12} md={12}>
                    <Typography>Description</Typography>
                    <Paper variant="outlined">
                      <CustomToolbar />
                      <QuillEditor
                        required
                        className={classes.editor}
                        value={values.description}
                        onChange={(value) =>
                          setFieldValue("description", value)
                        }
                        modules={modules}
                        placeholder={
                          "Write something here (maximum 2000 characters)..."
                        }
                        inputProps={{ maxLength: 2000 }}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                      // helperText={
                      //   touched.description && errors.description
                      //     ? "Please enter discription"
                      //     : ""
                      // }
                      />
                    </Paper>
                    {touched.description && errors.description && (
                      <Box mt={3}>
                        <FormHelperText error>
                          {errors.description}
                        </FormHelperText>
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      value={getBusinessLabel(values.businessType)}
                      onChange={(event, newValue) => {
                        setFieldValue("businessType", newValue.id);
                      }}
                      disablePortal
                      id="businessType"
                      options={BUSINESS_TYPE}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Business Type" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      required
                      id="establishYear"
                      label="Establish Year ('YYYY')"
                      value={values.establishYear}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(
                        touched.establishYear && errors.establishYear
                      )}
                      helperText={
                        touched.establishYear && errors.establishYear
                          ? "Please enter valid year"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      multiple
                      value={values.domains}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "domains",
                          newValue.map((option) => option.value || option)
                        );
                      }}
                      disablePortal
                      id="domains"
                      options={BUSINESS_DOMAIN}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Construction Domains"
                          placeholder="Select Domains"
                          value={tags}
                          onChange={(e) =>
                            setTags([...tags, e.target.value.split(",")])
                          }
                          error={Boolean(touched.domains && errors.domains)}
                          helperText={touched.domains && errors.domains}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={values.projectCount}
                      onChange={(event, newValue) => {
                        // console.log("BusinessUpdate: ", newValue);
                        setFieldValue("projectCount", newValue.id);
                      }}
                      disablePortal
                      id="projectCount"
                      required
                      options={PROJECT_COUNT}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="# of Projects Delivered"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      multiple
                      value={values.certificates}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "certificates",
                          newValue.map((option) => option.value || option)
                        );
                      }}
                      disablePortal
                      id="certificates"
                      options={ISO_CERTIFICATES}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="ISO Certificates"
                          error={Boolean(
                            touched.certificates && errors.certificates
                          )}
                          helperText={
                            touched.certificates && errors.certificates
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      required
                      id="websiteUrl"
                      label="Company Website URL"
                      value={values.websiteUrl}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.websiteUrl && errors.websiteUrl)}
                      helperText={
                        touched.websiteUrl && errors.websiteUrl
                          ? "Please enter valid url including http/https"
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Box sx={{ textAlign: "right", mt: 2, mb: 6 }}>
                  <Button
                    href="/my-business"
                    // color="secondary"
                    variant="contained"
                    sx={{
                      backgroundColor: "#757575",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#757575",
                        transition: "background-color 0.3s, box-shadow 0.3s",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                      },
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    // onClick={handleSubmit}
                    onClick={() => {
                      if (
                        errors.businessName ||
                        errors.businessName === undefined ||
                        errors.businessEmail ||
                        errors.businessEmail === undefined ||
                        errors.businessMobile ||
                        errors.businessMobile === undefined ||
                        errors.businessCategory ||
                        errors.businessCategory === undefined ||
                        errors.establishYear ||
                        errors.establishYear == undefined ||
                        errors.businessHeadline ||
                        errors.businessHeadline === undefined
                      ) {
                        if (
                          values.businessName &&
                          values.businessEmail &&
                          values.businessMobile &&
                          values.businessCategory &&
                          values.establishYear &&
                          // values.description!=="<p><br></p>" &&
                          values.businessHeadline
                        ) {
                          setErrorMsg("");
                        } else {
                          setErrorMsg("Please fill out all required fields");
                        }
                        setTimeout(() => {
                          setErrorMsg("");
                        }, 5000);
                        handleSubmit();
                      }
                    }}
                    // color="secondary"
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "black",
                        transition: "background-color 0.3s, box-shadow 0.3s",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                      },
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                  {errorMsg && (
                    <Typography
                      style={{
                        marginTop: "4px",
                        fontSize: "12px",
                        color: "red",
                      }}
                    >
                      {errorMsg}
                    </Typography>
                  )}
                </Box>
              </form>
            </CardContent>
          </Card>
        );
      }}
    </Formik>
  );
}

BusinessProfileDetailsForm.propTypes = {
  supplierId: PropTypes.number.isRequired,
  businessName: PropTypes.string.isRequired,
  gstin: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  onSubmitSuccess: PropTypes.func,
};

export default BusinessProfileDetailsForm;
