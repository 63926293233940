import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import FilterSection from "./FilterSection";
import TableSection from "./TableSection";

const CdMainSection = () => {
  return (
    <Grid
      sx={{
        // backgroundColor: "#F4F4F4",
        display: "flex",
        height: "100%",
        // overflow: "auto",
        padding: "10px 0px 10px 20px",
        gap: "30px",
      }}
    >
      {/* First Section (Fixed Width) */}
      <Grid style={{ width: "260px" }}>
        <FilterSection />
      </Grid>

      {/* Second Section (Auto Width) */}
      <div
        className="flex-grow overflow-x-auto overflow-y-auto pr-2"
      >
        <TableSection />
      </div>
    </Grid>
  );
};

export default CdMainSection;
