import React, { useRef } from 'react'
import FindNextContractor from './FindNextContractor';
import CompanyLogoSlider from './CompanyLogoSlider';
import EasySteps from './EasySteps';
import Navbar from './Navbar';
import BenefitsOfNeevay from './BenefitsOfNeevay';
import Testimonials from './Testimonials';
import RFQmngt from './RFQmngt';
import Footer from './Footer';
import Hero from './Hero';




const BuyerPageMain = () => {

    const homeRef = useRef(null);
    const scrollToHome = () => {
        homeRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  return (
    <div>
         <Navbar />
            <div className="lg:h-[677px] xl:h-[721px] md:h-[587px] h-[636px] w-full bg-cover bg-center lg:bg-top bg-no-repeat bg-[url('https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_background_cropped.jpg')] ">
                <div className='h-full bg-gradient-to-r from-[#24242D] from-[-44%] to-transparent'>
                    <Hero ref={homeRef} />
                </div>
            </div>
            <CompanyLogoSlider />
            
            <FindNextContractor scrollToHome={scrollToHome} />
            <EasySteps scrollToHome={scrollToHome} />
            <BenefitsOfNeevay scrollToHome={scrollToHome} />
            <Testimonials />
            <RFQmngt />
            
            <Footer />
    </div>
  )
}

export default BuyerPageMain