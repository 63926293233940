import {
  SEARCH_FIELDS,
  SEARCH_FACETS,
  SEARCH_LABELS,
  DEFAULT_IMAGES,
} from "config";

export function processSearchResults(inSearchList) {
  const results = [];

  if (inSearchList) {
    inSearchList.map((item) => {
      let searchItem = {
        businessName: item[SEARCH_FIELDS.BUSINESS_NAME],
        hightlights: item[SEARCH_FIELDS.BUSINESS_HEADLINE]?.join(),
        headOffice: item[SEARCH_FIELDS.HEADOFFICE]?.join(),
        locations: item[SEARCH_FIELDS.LOCATIONS]?.join(", ").slice(0, 120),
        addresses_line1: item[SEARCH_FIELDS.ADDRESSES_LINE1]?.[0],
        addresses_line2: item[SEARCH_FIELDS.ADDRESSES_LINE2]?.[0],
        addresses_city: item[SEARCH_FIELDS.ADDRESSES_CITY]?.[0],
        addresses_district: item[SEARCH_FIELDS.ADDRESSES_DISTRICT]?.[0],
        addresses_state: item[SEARCH_FIELDS.ADDRESSES_STATE]?.[0],
        addresses_country: item[SEARCH_FIELDS.ADDRESSES_COUNTRY]?.[0],
        businessType: item[SEARCH_FIELDS.BUSINESS_TYPE]?.join(),
        projectCount: item[SEARCH_FIELDS.PROJECT_COUNT]?.join(),
        isISOCertified:
          item[SEARCH_FIELDS.IS_ISO_CERTIFIED]?.length > 0
            ? item[SEARCH_FIELDS.IS_ISO_CERTIFIED][0]
            : false,
        establishYear: item[SEARCH_FIELDS.ESTABLISH_YEAR]?.join(),
        imageUrl: DEFAULT_IMAGES.SEARCH,
        url: item[SEARCH_FIELDS.SUPPLIER_URL]?.join(),
        supplierId: item[SEARCH_FIELDS.SUPPLIER_ID]?.join(),
        serviceDetails: item[SEARCH_FIELDS.SERVICE]?.join(),
        subscriptionType: item[SEARCH_FIELDS.SUBSCRIPTION_TYPE]?.join(),
        logoUrl: item[SEARCH_FIELDS.LOGO_URL]?.join(),
        gstCertified: item[SEARCH_FIELDS.GSTIN]?.join(),
        annualTurnOver: item[SEARCH_FIELDS.ANNUAL_TURNOVER]?.join(),
        businessCategory: item["businessProfile.businessCategory"]?.join(),
        owners: item.owners,
        businessEmail: item[SEARCH_FIELDS.BUSINESS_EMAIL]?.join(),
        contact: item[SEARCH_FIELDS.BUSINESS_CONTACT]?.join(),
        serviceOffered: item[SEARCH_FIELDS.SERVICE_NAME],
        domains: item[SEARCH_FIELDS.DOMAINS],
        gstNumber: item[SEARCH_FIELDS.GST_NUMBER]?.join(),
        businessMobileNumber:
          item[SEARCH_FIELDS.BUSINESS_MOBILE_NUMBER]?.join() ||
          item[SEARCH_FIELDS.BUSINESS_CONTACT]?.join(),
        isoCertificates: item[SEARCH_FIELDS.ISO_CERTIFICATES],
        projectNames: item[SEARCH_FIELDS.PROJECT_NAME],
        description: item[SEARCH_FIELDS.DESCRIPTION]?.join(),
        country: item[SEARCH_FIELDS.REGIONS_COUNTRY] ?? [],
        state: item[SEARCH_FIELDS.REGIONS_STATE] ?? [],
        selectedcities: item[SEARCH_FIELDS.REGIONS_SELECTED_CITIES] ?? [],
        clients: item[SEARCH_FIELDS.CLIENTS] ?? [],
      };
      results.push(searchItem);
    });
  }

  return results;
}

function processFacets(inFacets) {
  const facets = [];

  // console.log("processFacets: ", inFacets);

  inFacets?.map(function (item, index) {
    // console.log("processFacets item:", item, index, inFacets[index + 1]);
    if (typeof item === "string" && inFacets[index + 1] > 0) {
      facets.push({ label: item, count: inFacets[index + 1] });
    }
  });
  // console.log("processFacets: ", facets);

  return facets;
}

export function processSearchFacets(inFacets) {
  const facets = [];

  if (inFacets) {
    // console.log("processSearchFacets() ", inFacets);

    facets.push({
      title: SEARCH_LABELS.CATEGORY,
      facets: processFacets(inFacets[SEARCH_FACETS.CATEGORY]),
    });
    facets.push({
      title: SEARCH_LABELS.SUPPLIER_TYPE,
      facets: processFacets(inFacets[SEARCH_FACETS.SUPPLIER_TYPE]),
    });

    // facets.push({
    //   title: SEARCH_LABELS.SERVICES,
    //   facets: processFacets(inFacets[SEARCH_FACETS.SERVICE_DETAILS]),
    // });

    facets.push({
      title: SEARCH_LABELS.IS_ISO_CERTIFIED,
      facets: processFacets(inFacets[SEARCH_FACETS.IS_ISO_CERTIFIED]),
    });

    facets.push({
      title: SEARCH_LABELS.IS_GST_CERTIFIED,
      facets: processFacets(inFacets[SEARCH_FACETS.IS_GST_CERTIFIED]),
    });

    facets.push({
      title: SEARCH_LABELS.ANNUAL_TURNOVER,
      facets: processFacets(inFacets[SEARCH_FACETS.ANNUAL_TURNOVER]),
    });

    facets.push({
      title: SEARCH_LABELS.CERTIFICATES,
      facets: processFacets(inFacets[SEARCH_FACETS.CERTIFICATES]),
    });

    facets.push({
      title: SEARCH_LABELS.BUSINESS_TYPE,
      facets: processFacets(inFacets[SEARCH_FACETS.BUSINESS_TYPE]),
    });

    facets.push({
      title: SEARCH_LABELS.DOMAINS,
      facets: processFacets(inFacets[SEARCH_FACETS.DOMAINS]),
    });

    facets.push({
      title: SEARCH_LABELS.PROJECT_COUNT,
      facets: processFacets(inFacets[SEARCH_FACETS.PROJECT_COUNT]),
    });

    // facets.push({
    //   title: SEARCH_LABELS.ESTABLISH_YEAR,
    //   facets: processFacets(inFacets[SEARCH_FACETS.ESTABLISH_YEAR]),
    // });

    // facets.push({
    //   title: SEARCH_LABELS.LOCATIONS,
    //   facets: processFacets(inFacets[SEARCH_FACETS.LOCATIONS]),
    // });

    // facets.push({
    //   title: SEARCH_LABELS.CITY,
    //   facets: processFacets(inFacets[SEARCH_FACETS.CITY]),
    // });
  }

  return facets;

  // console.log("processSearchFacets() facets:", facets);
}

export function processCompanySearchResults(inSearchList) {
  const results = [];

  if (inSearchList) {
    inSearchList.map((item) => {
      let searchItem = {
        businessName: item[SEARCH_FIELDS.BUSINESS_NAME]?.join(),
        // supplierId: item[SEARCH_FIELDS.SUPPLIER_ID]?.[0],
      };
      results.push(searchItem);
    });
  }

  return results;
}

export function getSelectedFacetString(selectedFacets) {
  let retString = "";
  const annualTurnOverFacetString = {
    "Less than 40 Lacs": ["Rs. 0 to 40 lakhs"],
    "Greater than 40 Lacs": [
      "Rs. 40 lakhs to 1.5 Cr.",
      "Rs. 1.5 Cr. to 5 Cr.",
      "Rs. 5 Cr. to 25 Cr.",
      "Rs. 25 Cr. to 100 Cr.",
      "Rs. 100 Cr. to 500 Cr.",
      "Rs. 500 Cr. and above",
    ],

    "Greater than 1.5 Crores": [
      "Rs. 1.5 Cr. to 5 Cr.",
      "Rs. 5 Cr. to 25 Cr.",
      "Rs. 25 Cr. to 100 Cr.",
      "Rs. 100 Cr. to 500 Cr.",
      "Rs. 500 Cr. and above",
    ],

    "Greater than 5 Crores": [
      "Rs. 5 Cr. to 25 Cr.",
      "Rs. 25 Cr. to 100 Cr.",
      "Rs. 100 Cr. to 500 Cr.",
      "Rs. 500 Cr. and above",
    ],
    "Greater than 25 Crores": [
      "Rs. 25 Cr. to 100 Cr.",
      "Rs. 100 Cr. to 500 Cr.",
      "Rs. 500 Cr. and above",
    ],
    "Greater than 100 Crores": [
      "Rs. 100 Cr. to 500 Cr.",
      "Rs. 500 Cr. and above",
    ],
    "Greater than 500 Crores": ["Rs. 500 Cr. and above"],
  };

  const modifyFacets = selectedFacets.map((data) => {
    if (data?.facet === "Annual Turnover") {
      return { ...data, values: annualTurnOverFacetString[data?.values[0]] };
    } else {
      return data;
    }
  });
  modifyFacets.map((item) => {
    if (retString) {
      retString += " AND ";
    }
    let facetName = getFacetNameByLabel(item.facet);

    let valueStr = item?.values?.map((value) => `"${value}"`).join(" OR ");

    retString += `${facetName}:(${valueStr})`;
    //retString += `${facetName}:(${item?.values?.join(" OR ")})`;
  });

  return retString;
}

export function getFacetNameByLabel(label) {
  // console.log("getFacetNameByLabel() label=", label);
  switch (label) {
    case SEARCH_LABELS.CATEGORY:
      return SEARCH_FACETS.CATEGORY;

    case SEARCH_LABELS.BUSINESS_TYPE:
      return SEARCH_FACETS.BUSINESS_TYPE;

    case SEARCH_LABELS.DOMAINS:
      return SEARCH_FACETS.DOMAINS;

    case SEARCH_LABELS.IS_ISO_CERTIFIED:
      return SEARCH_FACETS.IS_ISO_CERTIFIED;

    case SEARCH_LABELS.ANNUAL_TURNOVER:
      return SEARCH_FACETS.ANNUAL_TURNOVER;

    case SEARCH_LABELS.IS_GST_CERTIFIED:
      return SEARCH_FACETS.IS_GST_CERTIFIED;

    case SEARCH_LABELS.ESTABLISH_YEAR:
      return SEARCH_FACETS.ESTABLISH_YEAR;

    case SEARCH_LABELS.LOCATIONS:
      return SEARCH_FACETS.LOCATIONS;

    case SEARCH_LABELS.CITY:
      return SEARCH_FACETS.CITY;

    case SEARCH_LABELS.CERTIFICATES:
      return SEARCH_FACETS.CERTIFICATES;

    case SEARCH_LABELS.PROJECT_COUNT:
      return SEARCH_FACETS.PROJECT_COUNT;

    case SEARCH_LABELS.SERVICES:
      return SEARCH_FACETS.SERVICE_DETAILS;

    case SEARCH_LABELS.SUPPLIER_TYPE:
      return SEARCH_FACETS.SUPPLIER_TYPE;

    case SEARCH_LABELS.SUBSCRIPTION_TYPE:
      return SEARCH_FACETS.SUBSCRIPTION_TYPE;

    case SEARCH_LABELS.IS_PROJECT_AVAILABLE:
      return SEARCH_FACETS.IS_PROJECT_AVAILABLE;

    case SEARCH_LABELS.IS_CLIENTS_AVAILABLE:
      return SEARCH_FACETS.IS_CLIENTS_AVAILABLE;

    default:
      return "";
  }
}

export function updateSearchFilter(
  existingFilter,
  facetTitle,
  facetValue,
  isChecked
) {
  const filterSet = [];
  console.log(
    "selected facets array = ",
    existingFilter,
    "/n 1. Title = ",
    facetTitle,
    "/n 2. Value = ",
    facetValue,
    "/n 3. True or Flase = ",
    isChecked
  );
  if (isChecked) {
    if (existingFilter.length == 0) {
      // console.log("updateSearchFilter() adding first item ");
      filterSet.push({ facet: facetTitle, values: [facetValue] });
    } else {
      let isFound = false;

      // console.log("if condition");
      existingFilter.map((item) => {
        // console.log(item.facet, " === ", facetTitle);
        if (item.facet === facetTitle) {
          isFound = true;
          // console.log("updateSearchFilter() update item ", item);
          if (
            facetTitle == "ISO Certified" ||
            facetTitle == "subscriptionType" ||
            facetTitle == "GST Certified" ||
            facetTitle == "Annual Turnover"
          ) {
            filterSet.push({
              facet: facetTitle,
              values: [facetValue],
            });
          } else {
            filterSet.push({
              facet: facetTitle,
              values: [...new Set([...item.values, facetValue])],
            });
          }
        } else {
          // console.log("updateSearchFilter() adding item ", item);
          filterSet.push({ facet: item.facet, values: item.values });
        }
      });

      if (!isFound) {
        filterSet.push({ facet: facetTitle, values: [facetValue] });
      }
    }
  } else {
    // console.log("is flase come in");
    let updatedFilterSet = existingFilter.map((item, index) => {
      const newItem = { ...item };
      if (newItem.facet === facetTitle) {
        // console.log("is if else", newItem.facet, " ===", facetTitle);
        newItem.values = newItem.values.filter((value) => value !== facetValue); // remove that value froam array
      }
      return newItem;
    });
    updatedFilterSet = updatedFilterSet.filter(
      (iteam) => iteam.values?.length !== 0
    ); //if the array is  null then dont push into obj of array
    return updatedFilterSet;
  }

  return filterSet;
}

// export const filterString = filterFacet => {
//   let retString = '';
//   const keyArr = Object.keys(filterFacet);
//   keyArr.map(keyItem => {
//     // if (filterFacet[keyItem].length) {
//     //   if (retString) {
//     //     retString += ' AND ';
//     //   }
//     //   retString += `${keyItem}:${filterFacet[keyItem][0]}`;
//     // }
//     if (filterFacet[keyItem]) {
//       if (retString) {
//         retString += ' AND ';
//       }
//       retString += `${keyItem}:${filterFacet[keyItem]}`;
//     }
//     return true;
//   });
//   return retString;
// };

export function updateCompanyDirectory({
  field,
  value,
  isMultiple,
  appliedFilters,
}) {
  if (isMultiple) {
    if (appliedFilters[field]) {
      const tempArray = appliedFilters[field].includes(value)
        ? appliedFilters[field].filter((data) => data !== value)
        : [...appliedFilters[field], value];
      return {
        ...appliedFilters,
        [field]: tempArray,
      };
    } else {
      return {
        ...appliedFilters,
        [field]: [value],
      };
    }
  } else {
    if (appliedFilters[field]) {
      const tempVal = appliedFilters[field] === value ? "" : value;
      return {
        ...appliedFilters,
        [field]: tempVal,
      };
    } else {
      return {
        ...appliedFilters,
        [field]: value,
      };
    }
  }
}
