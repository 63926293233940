import { paginatedSearch } from "actions/searchActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterSection, TableSection } from "views/CompanyDirectory/components";
import NeevayNetwork from "views/NeevayNetwork";
import FilterPanel from "views/NeevayNetwork/components/FilterPanel";
import GlobalSearchBar from "views/NeevayNetwork/components/GlobalSearchBar";
import SearchNew from "views/NeevayNetwork/components/SearchNew";
import Table from "views/NeevayNetwork/components/Table";

const SelectVendorsSlider = ({
  rfqId,
  sliderName = "NN",
  openSlider,
  handleSliderClose,
  invitedVendors
}) => {
  const auth = useSelector((state) => state.auth);
  const user = auth?.user;
  const entityName = user?.userEntity?.entityName;
  const directoryName = user?.userEntity?.directoryName;

  const searchResult = useSelector((state) => state.search); 
  const hasResults = searchResult.results && searchResult.results.length > 0;


  return (
    <div
      className={`fixed z-20 top-0 right-0 h-full w-[90%] bg-gray-100 transition-transform duration-500 transform ${
        openSlider ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <button className="ml-3" onClick={handleSliderClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="text-gray-700 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      {/* {openSlider && <NeevayNetwork />} */}
      {openSlider && sliderName == "NN" ? (
        <div>
          <div className="flex justify-between items-center pl-5">
            <h1 className="text-2xl font-bold">Vendor Marketplace</h1>
            <SearchNew />

            <div></div>
          </div>

          <div className="flex py-3 mt-2 pl-5 gap-x-5">
            {(hasResults || searchResult?.selectedFacets?.length > 1) && <div className="w-[260px]">
              <FilterPanel />
            </div>}

            <div
              // className="flex flex-col gap-y-4"
              style={{ height: "calc(100vh - 120px)" }}
              className="flex-grow overflow-x-auto pr-2"
            >
              <Table
                isSliderView={true}
                rfqId={rfqId}
                handleSliderClose={handleSliderClose}
                invitedVendors={invitedVendors}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-screen p-3 pr-0 gap-7">
          <div className="h-full flex flex-col" style={{ width: "260px" }}>
            <div className="h-10 mb-4">
              {/* <p className="font-bold text-2xl">{directoryName} Directory</p> */}
              <p className="font-bold text-2xl">Internal Vendors</p>
            </div>
            <div className="flex-grow overflow-x-auto overflow-y-auto">
              <FilterSection />
            </div>
          </div>

          <div className="flex-grow overflow-x-auto overflow-y-auto pr-2">
            <TableSection
              isSliderView={true}
              rfqId={rfqId}
              handleSliderClose={handleSliderClose}
              invitedVendors={invitedVendors}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectVendorsSlider;
