import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "utils/axios";
import {
  COMPANY_DIR_ADD_VENDORS_SUCCESS,
  COMPANY_DIR_EDIT_VENDORS_SUCCESS,
  COMPANY_DIR_DELETE_VENDORS_SUCCESS,
} from "actions/companyDirectoryActions";
import { useDispatch } from "react-redux";
import Loading from "components/Loading";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { BUSINESS_DOMAIN } from "config";
import StaticPopup from "components/StaticPopup";

const gstRegex =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
const mobileNumberRegex = /^[6-9]\d{9}$/;
const uniqueArray = (message) => {
  return Yup.array().test("unique", message, (array) => {
    if (array) {
      const normalizedArray = array.map((item) => item.toLowerCase());
      const set = new Set(normalizedArray);
      return set.size === array.length;
    }
    return true;
  });
};

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  // addressLine: Yup.string().required("Required"),
  // city: Yup.string().required("Required"),
  // state: Yup.string().required("Required"),
  pincode: Yup.string().matches(/^[1-9][0-9]{5}$/, "Invalid pincode"),
  // .required("Required"),
  gstNumber: Yup.string().matches(gstRegex, "Invalid GST number"),
  approved: Yup.string().required("Required"),
  contactDetails: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().required("Required"),
        mobile: Yup.string()
          .matches(mobileNumberRegex, "Invalid mobile number")
          .required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
      })
    )
    .required("Must have contact details")
    .min(1, "Minimum of 1 contact detail"),
  services: uniqueArray("No duplicate services allowed")
    .of(Yup.string().required("Required"))
    .min(1, "Required"),
  sectors: uniqueArray("No duplicate sectors allowed").of(Yup.string()),
  type: uniqueArray("No duplicate types allowed")
    .of(Yup.string().required("Required"))
    .min(1, "Required"),
  serviceLocations: uniqueArray("No duplicate locations allowed")
    .of(Yup.string().required("Required"))
    .min(1, "Required"),
});

const AddVendorForm = ({
  setOpenAddVendorPopup,
  user,
  setSnackbarOpen,
  setSnackbarText,
  editVendorDetails,
  isEdit = false,
}) => {
  const sectorsInputRef = useRef(null);
  const ServicesInputRef = useRef(null);
  const locationInputRef = useRef(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const [openDeletePopup, setOpenDeletePopup] = useState(false); // State to manage popup visibility
  const [loadingDelete, setLoadingDelete] = useState(false); // State to manage loading state in the popup
  const [deleteStatus, setDeleteStatus] = useState(null);

  const userId = user?.userId;
  const userName = user?.name;
  const entityId = user?.userEntity?.entityId;

  const handleSubmit = async (values, { resetForm }) => {
    const newTags = editVendorDetails?.tags?.map((data) => {
      if (data?.userId == userId)
        return { ...data, tags: values?.services || [] };
      return data;
    });
    // Format the formData as per your requirements
    const formData = {
      vendorName: values?.name,
      entityId,
      gstNumber: values?.gstNumber,
      //   gstTurnOverRange: "50L-1Cr",
      isApproved: values?.approved == "yes",
      contactDetails: values?.contactDetails,
      tags: isEdit ? newTags : values?.services,
      servicesOffering: values?.services?.map((service) => {
        return { serviceName: service };
      }),
      serviceLocations: values?.serviceLocations || [],
      location: [
        {
          addressLine1: values?.addressLine,
          pinCode: values?.pincode,
          city: values?.city,
          state: values?.state,
        },
      ],
      addedBy: userName,
      createdBy: userId,
      sectors: values?.sectors,
      type: values?.type,
    };

    try {
      const response = !isEdit
        ? await axios.post("/api/directory/add/vendor", formData)
        : await axios.put(
            `/api/directory/update/vendor?vendorId=${editVendorDetails?.vendorId}&entityId=${entityId}`,
            formData
          );
      dispatch({
        type: isEdit
          ? COMPANY_DIR_EDIT_VENDORS_SUCCESS
          : COMPANY_DIR_ADD_VENDORS_SUCCESS,
        payload: response?.data?.payload,
      });
      setOpenAddVendorPopup(false);
      setSnackbarOpen(true);
      setSnackbarText("Vendor Edited Successfully")
      resetForm();
      setSelectedLocations([]);
    } catch (error) {
      // Handle errors if the API request fails
      console.error("Error saving data:", error);
      const message = error?.response?.data?.message || "";
      setErrorMessage(message);
      setTimeout(() => {
        setErrorMessage("");
      }, 6000);
    }
  };

  const handleDeleteVendor = async () => {
    setLoadingDelete(true);
    try {
      await axios.delete(
        `/api/directory/delete/vendor?vendorId=${editVendorDetails?.vendorId}&entityId=${entityId}&userId=${userId}&userName=${encodeURIComponent(userName)}`
      );
      dispatch({ type: COMPANY_DIR_DELETE_VENDORS_SUCCESS, payload: editVendorDetails?.vendorId });
      setSnackbarOpen(true);
      setSnackbarText("Vendor Deleted Successfully")
      setOpenDeletePopup(false);
      setOpenAddVendorPopup(false);
    } catch (error) {
      console.error("Error deleting vendor:", error);
      const message = error?.response?.data?.message || "";
      setErrorMessage(message);
      setTimeout(() => {
        setErrorMessage("");
      }, 6000);
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleOpenDeletePopup = () => setOpenDeletePopup(true);
  const handleCloseDeletePopup = () => setOpenDeletePopup(false);

  const handleAddLocation = (location) => {
    setSelectedLocations((preVal) => [...preVal, location]);
  };

  const handleDeleteLocation = (location) => {
    setSelectedLocations((preVal) =>
      preVal.filter((data) => data !== location)
    );
  };
  useEffect(() => {
    if (isEdit) {
      setSelectedLocations(editVendorDetails?.serviceLocations || []);
    } else {
      setSelectedLocations([]);
    }
  }, [editVendorDetails]);
  return (
    <div className="max-w-3xl mx-auto pt-2 bg-white rounded-md ">
      <Formik
        enableReinitialize
        initialValues={{
          name: isEdit ? editVendorDetails?.vendorName : "",
          gstNumber: isEdit ? editVendorDetails?.gstNumber : "",
          approved: isEdit
            ? editVendorDetails?.isApproved
              ? "yes"
              : "no"
            : "",
          type: editVendorDetails?.type || [],
          contactDetails: isEdit
            ? editVendorDetails?.contactDetails
            : [{ name: "", mobile: "", email: "" }],
          services: isEdit
            ? editVendorDetails.servicesOffering?.map(
                (entry) => entry?.serviceName
              )
            : [],
          serviceLocations: isEdit ? editVendorDetails.serviceLocations : [],
          sectors: isEdit ? editVendorDetails?.sectors : [],
          addressLine: isEdit
            ? editVendorDetails?.location[0]?.addressLine1 || ""
            : "",
          city: isEdit ? editVendorDetails?.location[0]?.city || "" : "",
          state: isEdit ? editVendorDetails?.location[0]?.state || "" : "",
          pincode: isEdit ? editVendorDetails?.location[0]?.pinCode || "" : "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, submitForm, setFieldValue }) => (
          <Form>
            <h2 className="text-xl font-bold">Vendor Details</h2>
            <div className="grid grid-cols-2 gap-10 mt-4">
              <div>
                <label className="block text-sm font-medium">
                  Company Name<span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="name"
                  id="name"
                  className="mt-1 block w-full border border-gray-300 rounded-md"
                />
                {!values.name && <div className="mt-1 text-xs text-red-500">Required</div>}
                {/* <ErrorMessage
                  name="name"
                  component="div"
                  className="mt-2 text-xs text-red-500"
                /> */}

                <label className="block text-sm font-medium mt-4">
                  GST Number <span className="text-gray-500">{"(Optional)"}</span>
                </label>
                <Field
                  type="text"
                  name="gstNumber"
                  id="gstNumber"
                  className="mt-1 block w-full border border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="gstNumber"
                  component="div"
                  className="mt-2 text-xs text-red-500"
                />

                <div className="mt-4">
                  <label className="block text-sm font-medium">
                    Approved<span className="text-red-500">*</span>
                  </label>
                  <p className="mt-1 text-xs text-gray-400">
                    Note: Is this vendor approved for your company?
                  </p>
                  <div className="mt-2">
                    <label className="inline-flex items-center">
                      <Field
                        type="radio"
                        name="approved"
                        id="approved"
                        value="yes"
                        className="form-radio"
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="inline-flex items-center ml-6">
                      <Field
                        type="radio"
                        name="approved"
                        id="approved"
                        value="no"
                        className="form-radio"
                      />
                      <span className="ml-2">No</span>
                    </label>
                    {!values.approved && <div className="mt-1 text-xs text-red-500">Required</div>}
                    {/* <ErrorMessage
                      name="approved"
                      component="div"
                      className="mt-2 text-xs text-red-500"
                    /> */}
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium">
                  Type<span className="text-red-500">*</span>
                </label>
                <div className="mt-2 flex flex-col gap-1 space-y-2">
                  {[
                    "Contractor (Providing and Fixing)",
                    "Labour Contractor",
                    "Material Supplier",
                    "Consultant",
                    "Others",
                  ].map((type) => (
                    <label key={type} className="inline-flex items-start">
                      <Field
                        type="checkbox"
                        name="type"
                        id="type"
                        value={type}
                        className="w-4 h-4 mt-1 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <span className="ml-2">{type}</span>
                    </label>
                  ))}
                {!values?.type?.length > 0 && <div className="mt-1 text-xs text-red-500">Required</div>}
                  {/* <ErrorMessage
                    name="type"
                    component="div"
                    className="mt-2 text-xs text-red-500"
                  /> */}
                </div>
              </div>
            </div>
            <hr className="my-6" />
            <h2 className="text-xl font-bold">Contact Details</h2>
            <FieldArray name="contactDetails" id="contactDetails">
              {({ push, remove }) => (
                <>
                  {values.contactDetails.map((contact, index) => (
                    <div
                      key={index}
                      className="mt-4 grid grid-cols-4 gap-4 pr-6 items-start"
                    >
                      <div>
                        <label className="block text-sm font-medium">
                          Name<span className="text-red-500">*</span>
                        </label>
                        <Field
                          type="text"
                          name={`contactDetails[${index}].name`}
                          className="mt-1 block w-full border border-gray-300 rounded-md"
                        />
                        {!values.contactDetails[index].name  && <div className="mt-1 text-xs text-red-500">Required</div>}

                        {/* <ErrorMessage
                          name={`contactDetails[${index}].name`}
                          component="div"
                          className="mt-2 text-xs text-red-500"
                        /> */}
                      </div>
                      <div>
                        <label className="block text-sm font-medium">
                        Whatsapp Number<span className="text-red-500">*</span>
                        </label>
                        <Field
                          type="text"
                          name={`contactDetails[${index}].mobile`}
                          className="mt-1 block w-full border border-gray-300 rounded-md"
                        />
                        { !values.contactDetails[index].mobile   && <div className="mt-1 text-xs text-red-500">Required</div>}

                        {/* <ErrorMessage
                          name={`contactDetails[${index}].mobile`}
                          component="div"
                          className="mt-2 text-xs text-red-500"
                        /> */}
                      </div>
                      <div className="relative col-span-2">
                        <label className="block text-sm font-medium">
                          Email<span className="text-red-500">*</span>
                        </label>
                        <Field
                          type="email"
                          name={`contactDetails[${index}].email`}
                          className="mt-1 block w-full border border-gray-300 rounded-md"
                        />
                        {!values.contactDetails[index].email && <div className="mt-1 text-xs text-red-500">Required</div>}

                        {/* <ErrorMessage
                          name={`contactDetails[${index}].email`}
                          component="div"
                          className="mt-2 text-xs text-red-500"
                        /> */}
                        {values.contactDetails?.length > 1 && (
                          <button
                            type="button"
                            className="absolute top-0 left-full mt-8 ml-1 text-red-500"
                            onClick={() => remove(index)}
                          >
                            <svg
                              className="w-5 h-5"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              tabindex="-1"
                              title="DeleteForever"
                            >
                              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zm2.46-7.12 1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"></path>
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    className={`mt-4 px-4 py-2 rounded-md ${
                      values.contactDetails.every(
                        (contact) => contact.name && contact.mobile && contact.email
                      )
                        ? "bg-blue-500 text-white"
                        : "bg-gray-300 text-gray-500 cursor-not-allowed"
                    }`}
                    onClick={() => push({ name: "", mobile: "", email: "" })}
                    disabled={values.contactDetails.every(
                      (contact) => !contact.name && !contact.mobile && !contact.email
                    )}
                  >
                    + Add More
                  </button>
                </>
              )}
            </FieldArray>
            <hr className="my-6" />
            <h2 className="text-xl font-bold mb-3">Sectors <span className="text-gray-500 text-sm">{"(Optional)"}</span></h2>
            <Autocomplete
              multiple
              value={values?.sectors}
              onChange={(event, newValue) => {
                setFieldValue(
                  "sectors",
                  newValue.map((option) => option.value || option)
                );
              }}
              disablePortal
              id="sectors"
              options={Array.from(
                new Set([...BUSINESS_DOMAIN, ...values?.sectors])
              )}
              freeSolo
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ outline: "none" }}
                  placeholder="Select sectors"
                  value={tags}
                  onChange={(e) =>
                    setTags([...tags, e.target.value.split(",")])
                  }
                />
              )}
            />
            <ErrorMessage
              name="sectors"
              component="div"
              className="mt-2 text-xs text-red-500"
            />
            <hr className="my-6" />
            <h2 className="text-xl font-bold">Material / Service Details</h2>
            <p className="text-xs text-gray-400">
              Note: Add all material names or service names the vendor provides
              to your company.
            </p>
            <div className="flex gap-6">
              <div className="mt-8 w-[57%]">
                <FieldArray name="services" id="services">
                  {({ push, remove }) => (
                    <div
                      className="flex px-2 py-1 flex-wrap gap-1 border rounded-lg items-center"
                      onClick={() => {
                        ServicesInputRef.current.focus();
                      }}
                    >
                      <div className="flex flex-wrap gap-2">
                        {values.services.map((service, index) => (
                          <div
                            key={index}
                            className="flex gap-2 justify-between truncate items-center bg-blue-100 text-blue-700 text-sm font-medium px-2.5 rounded-full"
                          >
                            <span>{service}</span>
                            <button
                              type="button"
                              className="flex items-center justify-center w-4 h-4 bg-blue-200 hover:bg-blue-300 rounded-full focus:outline-none"
                              onClick={() => remove(index)}
                            >
                              <svg
                                className="w-3 h-3 text-blue-700"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                      <input
                        ref={ServicesInputRef}
                        type="text"
                        placeholder="Add materials/products..."
                        style={{ padding: "0px" }}
                        className="outline-none border-0 w-[50%] p-2 rounded-md focus:outline-none focus:border-transparent focus:ring-0 focus:ring-offset-0"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            if (e.target.value) {
                              push(e.target.value);
                              e.target.value = "";
                            }
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value) {
                            push(e.target.value);
                            e.target.value = "";
                          }
                        }}
                      />
                    </div>
                  )}
                </FieldArray>
              </div>
              <div className="mt-2 text-gray-400">
                <span className="text-sm text-gray-400">Example :</span>
                <div className="border text-sm rounded-md flex gap-2 px-2 py-1 items-center flex-wrap">
                  <div className="flex gap-2 justify-between truncate items-center bg-gray-100 text-gray-400 text-sm px-2.5 py-0.5 rounded-full">
                    <span>Cement</span>
                    <button
                      disabled={true}
                      type="button"
                      className="flex items-center justify-center w-4 h-4 bg-gray-200 rounded-full focus:outline-none"
                    >
                      <svg
                        className="w-3 h-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <span>Steel</span>
                  <span>{"<Hit Enter>"}</span>
                  <svg
                    className="w-4 h-4"
                    fill="currentColor"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="KeyboardReturnIcon"
                  >
                    <path d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z"></path>
                  </svg>
                </div>
              </div>
            </div>
            {!values.services?.length > 0  && <div className="mt-1 text-xs text-red-500">Required</div>}

            {/* <ErrorMessage
              name="services"
              component="div"
              className="mt-2 text-xs text-red-500"
            /> */}
            <hr className="my-6" />
            <h2 className="text-xl font-bold">Vendor Location</h2>
            <p className="text-xs text-gray-400">
              Note: Add the areas where this vendor can provide you services /
              materials.
            </p>
            <div className="flex gap-6">
              <div className="mt-8 w-[100%]">
                <FieldArray name="serviceLocations" id="serviceLocations">
                  {({ push, remove }) => (
                    <div
                      className="border px-2 py-1 rounded-md flex flex-wrap gap-2 items-center"
                      onClick={() => {
                        locationInputRef.current.focus();
                      }}
                    >
                      <div className="flex flex-wrap gap-2">
                        {values?.serviceLocations?.map((location, index) => (
                          <div
                            key={index}
                            className="flex gap-2 justify-between truncate items-center bg-blue-100 text-blue-700 text-sm font-medium px-2.5 py-0.5 rounded-full"
                          >
                            <span>{location}</span>
                            <button
                              type="button"
                              className="flex items-center justify-center w-4 h-4 bg-blue-200 hover:bg-blue-300 rounded-full focus:outline-none"
                              onClick={() => remove(index)}
                            >
                              <svg
                                className="w-3 h-3 text-blue-700"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                      <input
                        ref={locationInputRef}
                        type="text"
                        style={{ padding: "0px" }}
                        placeholder="Type here..."
                        className="outline-none border-0 w-[35%] rounded-md focus:outline-none focus:border-transparent focus:ring-0 focus:ring-offset-0"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            if (e.target.value) {
                              push(e.target.value);
                              e.target.value = "";
                            }
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value) {
                            push(e.target.value);
                            e.target.value = "";
                          }
                        }}
                      />
                    </div>
                  )}
                </FieldArray>
                {!values.serviceLocations?.length > 0  && <div className="mt-1 text-xs text-red-500">Required</div>}

                {/* <ErrorMessage
                  name="serviceLocations"
                  component="div"
                  className="mt-2 text-xs text-red-500"
                /> */}
              </div>

              <div className="mt-2">
                <span className="text-sm text-gray-400">Example1 :</span>
                <div className="text-gray-400 text-sm mb-3 border rounded-md flex gap-2 px-2 py-1 items-center flex-wrap">
                  {["Pune", "Mumbai", "Kalyan", "Lucknow"].map((city) => (
                    <div className="flex gap-2 justify-between truncate items-center bg-gray-100 text-gray-400 text-sm px-2.5 py-0.5 rounded-full">
                      <span>{city}</span>
                      <button
                        disabled={true}
                        type="button"
                        className="flex items-center justify-center w-4 h-4 bg-gray-200 rounded-full focus:outline-none"
                      >
                        <svg
                          className="w-3 h-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                  <span>Thane</span>
                  <span>{"<Hit Enter>"}</span>
                  <svg
                    className="w-4 h-4"
                    fill="currentColor"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="KeyboardReturnIcon"
                  >
                    <path d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z"></path>
                  </svg>
                </div>

                <span className="text-sm text-gray-400">Example2 :</span>
                <div className="border text-sm rounded-md flex gap-2 px-2 py-1 items-center flex-wrap">
                  <div className="flex gap-2 justify-between truncate items-center bg-gray-100 text-gray-400 text- px-2.5 py-0.5 rounded-full">
                    <span>All India</span>
                    <button
                      disabled={true}
                      type="button"
                      className="flex items-center justify-center w-4 h-4 bg-gray-200 rounded-full focus:outline-none"
                    >
                      <svg
                        className="w-3 h-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-6" />
            <h2 className="text-xl font-bold"> Address <span className="text-gray-500 text-sm">{"(Optional)"}</span></h2>
            <div className="p-2 pb-3 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mt-4">
                  Address
                </label>
                <Field
                  type="text"
                  name="addressLine"
                  id="addressLine"
                  className="mt-1 block w-full border border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="addressLine"
                  component="div"
                  className="mt-2 text-xs text-red-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mt-4">City</label>
                <Field
                  type="text"
                  name="city"
                  id="city"
                  className="mt-1 block w-full border border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  className="mt-2 text-xs text-red-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mt-4">State</label>
                <Field
                  type="text"
                  name="state"
                  id="state"
                  className="mt-1 block w-full border border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="state"
                  component="div"
                  className="mt-2 text-xs text-red-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mt-4">
                  Pincode
                </label>
                <Field
                  type="text"
                  name="pincode"
                  id="pincode"
                  className="mt-1 block w-full border border-gray-300 rounded-md"
                />
                <ErrorMessage
                  name="pincode"
                  component="div"
                  className="mt-2 text-xs text-red-500"
                />
              </div>
            </div>
            <hr className="my-2" />
            <button
              type="submit"
              className="mt-3 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md"
              onClick={async (e) => {
                setLoading(true);
                e.preventDefault();
                await submitForm();
                setLoading(false);
                const firstErrorField = Object.keys(errors)[0];
                const errorElement = document.getElementById(firstErrorField);
                if (errorElement) {
                  errorElement.scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
            >
              {loading ? (
                // <Loading />
                isEdit ?
                "Updating..." :
                "Adding..."
              ) : isEdit ? (
                "Save Changes"
              ) : (
                "Create Vendor"
              )}
            </button>

            {isEdit && (
              <button
                type="button"
                className="mt-3 border border-red-400 text-white ml-4 bg-red-500 text-black px-4 py-2 rounded-md"
                onClick={handleOpenDeletePopup}
              >
                Delete Vendor
              </button>
            )}


            {errorMessage != "" && (
              <p className="text-red-500 mt-2">{errorMessage}</p>
            )}
          </Form>
        )}
      </Formik>

      <StaticPopup 
        openModal={openDeletePopup}
        handleClose={handleCloseDeletePopup}
        header={deleteStatus === "DELETED" ? "Restore Vendor" : "Delete Vendor"}
      >
        {deleteStatus === "DELETED" ? (
          <p>Are you sure you want to restore this vendor?</p>
        ) : (
          <p>Are you sure you want to delete this vendor?</p>
        )}
        <div className="flex gap-4 mt-auto">
          <button
            className="p-2 px-4 border rounded-lg text-white bg-blue-700 hover:bg-blue-800 font-semibold"
            onClick={handleDeleteVendor}
          >
            {loadingDelete ? <Loading /> : "Yes"}
          </button>
          <button
            className="p-2 px-4 border rounded-lg font-semibold hover:bg-slate-100"
            onClick={handleCloseDeletePopup}
          >
            No
          </button>
        </div>
        {errorMessage && (
          <p className="text-sm text-red-700">{errorMessage}</p>
        )}
      </StaticPopup>
    </div>
  );
};

export default AddVendorForm;
