import React from 'react'
import { useNavigate } from 'react-router-dom'

const SaveMore = () => {
    const navigate = useNavigate()
    const handleDemoClick=()=>{
        navigate('/get-started')
        window.scroll(0,0)
    }
    return (
        <div className="bg-gradient-to-r from-[#24242D] to-[#0B0B1A] to-90% h-full">
            <div className="px-4 py-[80px] md:px-0 xl:py-[110px] bg-center bg-cover bg-[url('https://storagereponeevaydevcdn.blob.core.windows.net/business/mountains.svg')]">
                <div className='max-w-[1280px] mx-auto '>
                    <div className='md:px-[74px] xl:px-[68px]'>
                        <p className='uppercase text-[#FD6600] text-base md:text-sm xl:text-base font-bold'>benefit</p>
                        <p className='text-white text-xl md:text-2xl xl:text-5xl pt-4 md:pt-6'>Save more than <span className='font-bold'>6%</span> of project cost, no<br/> strings attached</p>
                    </div>
                    {/* for tab and laptop */}
                    <div className='hidden md:block md:px-[100px] lg:px-[160px] xl:px-[150px] py-[50px] xl:p-[98px] space-y-6 xl:space-y-[68px]'>
                        <div className='flex items-center'>
                            <p className='text-white md:text-xl ml-8 xl:ml-0 xl:text-[32px] font-medium'>India wide<br/>Vendor Pool</p>
                            <div className='bg-white h-[66px] xl:h-[78px] w-[2px] md:mx-3 xl:mx-6'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Broaden vendor<br/> Access, boost quality<br/> & cost</p>
                        </div>
                        <div className='flex items-center justify-end'>
                            <p className='text-white md:text-xl xl:text-[32px] font-medium'>Purchase<br/>Collaboration</p>
                            <div className='bg-white h-[66px] xl:h-[78px] w-[2px] md:mx-3 xl:mx-6'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Collaborate efficiently<br/> with Vendors &<br/> internal team</p>
                        </div>
                        <div className='flex items-center ml-8 xl:ml-12'>
                            <p className='text-white md:text-xl xl:text-[32px] font-medium'>Automated<br/>Quotation Comparison</p>
                            <div className='bg-white h-[66px] xl:h-[78px] w-[2px] md:mx-3 xl:mx-6'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Smart Bid<br/>Leveling For Fair<br/>Price Comparison</p>
                        </div>
                        <div className='flex items-center justify-end mr-24 xl:mr-36'>
                            <p className='text-white md:text-xl xl:text-[32px] font-medium'>Spend<br/>Analysis</p>
                            <div className='bg-white h-[66px] xl:h-[78px] w-[2px] md:mx-3 xl:mx-6'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Track & Identify<br/>Anomalies In<br/> Purchase cost</p>
                        </div>
                    </div>
                    {/* for mobile */}
                    <div className='md:hidden px-4 py-16 space-y-12'>
                        <div className='flex items-center'>
                            <p className='text-white text-lg'>India wide<br/>Vendor Pool</p>
                            <div className='bg-white h-[66px] w-[2px] mx-3'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Broaden vendor<br/>  Access,boost quality<br/> & cost</p>
                        </div>
                        <div className='flex items-center justify-end'>
                            <p className='text-white text-lg'>Purchase<br/>Collaboration</p>
                            <div className='bg-white h-[68px] w-[2px] mx-3'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Collaborate Efficiently<br/> with Vendors &<br/> Internal Team</p>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-white text-lg'>Automated<br/>Quotation <br/>Comparison</p>
                            <div className='bg-white h-[80px] w-[2px] mx-3'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Smart Bid <br/>Leveling For Fair<br/> Price Comparison</p>
                        </div>
                        <div className='flex items-center justify-end'>
                            <p className='text-white text-lg'>Spend<br/>Analysis</p>
                            <div className='bg-white h-[70px] w-[2px] mx-3'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Track & Identify <br/>Anomilies In<br/> Purchase Costs</p>
                        </div>
                    </div>
                    {/* <div className='md:hidden py-6 space-y-6'>
                        <div className='flex items-center justify-center'>
                            <p className='text-white text-lg'>India wide<br/>Vendor Pool</p>
                            <div className='bg-white h-[66px] w-[2px] mx-3'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Broaden vendor<br/>  Access,boost quality<br/> & cost</p>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-white text-lg'>Purchase<br/>Collaboration</p>
                            <div className='bg-white h-[68px] w-[2px] mx-3'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Collaborate Efficiently<br/> with Vendors &<br/> Internal Team</p>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-white text-lg'>Automated<br/>Quotation <br/>Comparison</p>
                            <div className='bg-white h-[80px] w-[2px] mx-3'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Smart Bid <br/>Leveling For Fair<br/> Price Comparison</p>
                        </div>
                        <div className='flex items-center justify-center'>
                            <p className='text-white text-lg'>Spend<br/>Analysis</p>
                            <div className='bg-white h-[70px] w-[2px] mx-3'></div>
                            <p className='text-[#D9B39A] text-md xl:text-xl'>Track & Identify <br/>Anomilies In<br/> Purchase Costs</p>
                        </div>
                    </div> */}
                    {/* <div className='md:hidden'>
                        <div >
                            <p className='text-white text-[70px] font-bold pt-[45px]'>95 <span className='text-2xl -ml-[16px] font-light'>%</span></p>
                            <p className='-mt-[10px] text-[#DDC5B0]'>additional vendors from the network</p>
                        </div>
                        <div >
                            <p className='text-white text-[54px] font-bold text-right pt-[48px]'>80 <span className='text-xl font-light -ml-[12px]'>%</span></p>
                            <p className='-mt-[10px] text-[#DDC5B0] text-right'>of manual workload eliminated</p>
                        </div>
                        <div>
                            <p className='text-white text-[56px] font-bold pt-[48px]'>25 <span className='text-xl font-light -ml-[12px]'>%</span></p>
                            <p className='-mt-[10px] text-[#DDC5B0]'>purchase anomalies uncovered</p>
                        </div>

                    </div> */}
                    <div className='flex justify-end  md:mr-[280px] xl:mr-[217px]'>
                        <button onClick={handleDemoClick} className='flex justify-evenly  items-center bg-white font-semibold border-l-8 border-orange-500 text-lg xl:text-xl mx-auto md:mx-0 md:text-base w-[220px] h-[51px] md:w-[204px] md:h-[44px] xl:w-[276px] xl:h-[62px]'>Book a Demo
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Arrow.svg" alt="" className='md:w-8 xl:w-auto' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaveMore
