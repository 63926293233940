import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import NorthWestIcon from '@mui/icons-material/NorthWest';
import { searchCitiesAction } from "actions/regionsActions";
import {
    neevayNetworkFilteredSearch,
    searchNeevayNetwork,
} from "actions/searchActions";
import { useNavigate } from "react-router-dom";

const NewSearchbar = () => {
    const cities = useSelector((state) => state.regions.cities);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    // const [regions, setRegions] = useState(cities);
    const [vendorClicked, setVendorClicked] = useState(false);
    // const [tradeClicked, setTradeClicked] = useState(false);
    const [regionClicked, setRegionClicked] = useState(false);
    const [selectedVendorType, setSelectedVendorType] = useState(""); //value selected in vendor type
    const [selectedTradeOrBusiness, setSelectedTradeOrBusiness] = useState(""); //value selected in trade or business
    const [selectedRegion, setSelectedRegion] = useState(""); //value selected in region
    const [showLeftDivider, setShowLeftDivider] = useState(true);
    const [showRightDivider, setShowRightDivider] = useState(true);
    const [selectedSection, setSelectedSection] = useState(""); //color of selected div
    const [searchInputPlaceHolder, setSearchInputPlaceHolder] = useState("Search from among 16000+ vendors");

    const dropdownRef = useRef(null);

    const mostPopularSearchesData = {
        Contractors: ["Civil", "Plumbing", "Waterproofing", "EPC", "Labour"],
        Suppliers: [
            "Cement",
            "TMT Bar",
            "Conveyor",
            "Electrical",
            "Building Materials",
        ],
        Consultants: [
            "RCC",
            "Electrical",
            "Project Management",
            "MK Design",
            "Civil",
        ],
    };

    const mostPopularSearchCityData = ["Pune", "Mumbai", "Delhi", "Bengaluru"];
    const initialRegions = cities
    const [regions, setRegions] = useState(cities);

    const handleChangeRegionValue = (e) => {
        const value = e.target.value;
        const searchTerm = value
            ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
            : "";

        setSelectedRegion(searchTerm);

        // Filter the initialRegions array directly
        const filterCities = initialRegions.filter((city) =>
            city.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setRegions(filterCities);
    };

    const handleSearchClick = (e) => {
        if (
            selectedVendorType === "" ||
            selectedTradeOrBusiness === "" ||
            selectedRegion === ""
        ) {
            setError(true);
            setShowLeftDivider(true);
            setShowRightDivider(true);
            setSelectedSection("");
        } else {
            setError(false);
            const filterStr =
                selectedVendorType === "Suppliers"
                    ? `businessProfile.businessCategory_str:("Supplier")`
                    : `businessProfile.businessCategory_str:("${selectedVendorType}")`;
            // dispatch(
            //     searchNeevayNetwork({
            //         searchKeyWord: selectedTradeOrBusiness,
            //         regionString: selectedRegion,
            //         filterStr,
            //         facetTitle: "Category",
            //         facetValue:
            //             selectedVendorType === "Suppliers"
            //                 ? "Supplier"
            //                 : selectedVendorType,
            //         isSelected: true,
            //     })
            // );
            const modifiedSearchKeyWord = selectedTradeOrBusiness?.replace(/ /g, "-");
            const regionValue = selectedRegion; // Example region value
            const modifiedVendorType = selectedVendorType?.replace(/ /g, "-");
            navigate(
                `/search-business-category/${modifiedSearchKeyWord}/${regionValue}?Category=${modifiedVendorType}`
            );
            setSelectedSection("");
        }
    };

    const handleVendorDropdown = () => {
        setVendorClicked(!vendorClicked); // Open vendor dropdown
        //setTradeClicked(false); // Close trade dropdown
        setRegionClicked(false); // Close region dropdown
        setSelectedSection("vendor");
    };

    const handleVendorHover = () => {
        setShowLeftDivider(false);
    };
    const handleVendorNonHover = () => {
        setShowLeftDivider(true);
    };

    const handleTradeDropdown = () => {
        // setTradeClicked(!tradeClicked);
        setVendorClicked(false);
        setRegionClicked(false);
        setShowLeftDivider(false);
        setShowRightDivider(false);
        setSelectedSection("trade");
    };

    const handleTradeOrBusinessHover = () => {
        setShowLeftDivider(false);
        setShowRightDivider(false);
    };
    const handleTradeOrBusinessNonHover = () => {
        setShowLeftDivider(true);
        setShowRightDivider(true);
    };

    const handleRegionDropdown = () => {
        setRegionClicked(!regionClicked);
        setVendorClicked(false);
        // setTradeClicked(false);
        setSelectedSection("region");
        setShowLeftDivider(true);
    };

    const handleRegionHover = () => {
        setShowRightDivider(false);
    };
    const handleRegionNonHover = () => {
        setShowRightDivider(true);
    };

    const handleSelectedVendorType = (value) => {
        setSelectedVendorType(value);
        setVendorClicked(!vendorClicked);
        setShowLeftDivider(true);
        setShowRightDivider(true);

        // Set placeholder based on value
        switch (value) {
            case "Material Supplier":
                setSearchInputPlaceHolder("What type of Material?");
                break;
            case "Labour Contractor":
                setSearchInputPlaceHolder("What type of Skill?");
                break;
            case "Contractor (Providing and Fixing)":
            case "Consultant":
                setSearchInputPlaceHolder("What type of Work?");
                break;
        }
    };

    const handleSelectedTradeOrBusiness = (value) => {
        setSelectedTradeOrBusiness(value);
        // setTradeClicked(!tradeClicked);
        setShowLeftDivider(true);
        setShowRightDivider(false);
    };

    const handleSelectedRegion = (value) => {
        setSelectedRegion(value);
        setRegionClicked(!regionClicked);
        setShowLeftDivider(true);
        setShowRightDivider(true);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearchClick();
            e.preventDefault();
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setVendorClicked(false);
                setRegionClicked(false);
                // setTradeClicked(false);
                setShowLeftDivider(true);
                setShowRightDivider(true);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        if (
            selectedVendorType !== "" &&
            selectedTradeOrBusiness !== "" &&
            selectedRegion !== ""
        ) {
            setError(false);
        }
    }, [selectedVendorType, selectedTradeOrBusiness, selectedRegion]);

    useEffect(() => {
        dispatch(searchCitiesAction());
    }, []);

    useEffect(() => {
        setRegions(cities)
    }, [cities])

    return (
        <div
            className={`flex justify-start items-center mt-10 ${selectedSection == "" ? "bg-[#FFFFFF]" : "bg-[#EBEBEB]"
                } md:w-[705px] md:h-[49px] lg:w-[858px] lg:h-[59px] border rounded-full shadow ${error
                    ? "border-[1px] border-[#EB5A4F] ring ring-red-200"
                    : "border-slate-300 "
                }`}
        >
            {/* vendor type */}
            <div
                onMouseEnter={handleVendorHover}
                onMouseLeave={handleVendorNonHover}
                onClick={handleVendorDropdown}
                className="relative "
                // style={{ backgroundColor: vendorBgColor, borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px" }}
                style={{ borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px" }}
            >
                <div
                    className={`flex items-center hover:rounded-full rounded-full md:w-[184px] md:h-[49px]  lg:w-[223px] lg:h-[59px] ${selectedSection == "vendor" ? "bg-white" : "hover:bg-[#E1E1E1]"
                        }`}
                >
                    <img
                        src="https://storagereponeevaydevcdn.blob.core.windows.net/business/user_icon_searchbar.svg"
                        alt="Vendor Type"
                        className="w-5 h-5 ml-4 bg-transparent"
                    />
                    <input
                        type="text"
                        className="h-6 md:w-[120px] lg:w-[150px] text-sm font-medium border-none focus:ring-transparent bg-transparent px-[12px] placeholder:font-medium placeholder:text-base mr-1 overflow-x-auto text-[#787878] placeholder-[#787878]"
                        value={selectedVendorType}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setSelectedVendorType(e.target.value)}
                        placeholder="Vendor Type"
                    />
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/searchbar_arrow.svg" alt="" />

                    {!selectedVendorType.trim() && error && (
                        <Tooltip
                            title="This field is required"
                            arrow
                            placement="top-end"
                            open
                            TransitionComponent={Zoom}
                            TransitionProps={{ timeout: 500 }}
                        >
                            <img
                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/circle_exclamation_solid_org.svg"
                                alt="Error"
                                className="absolute right-2 top-4 w-4 h-4"
                            />
                        </Tooltip>
                    )}
                    {vendorClicked && (
                        <ul
                            ref={dropdownRef}
                            className="absolute z-50 bg-[#fff] py-4 w-[233px] rounded-3xl md:top-[50px] lg:top-[60px] text-sm font-medium shadow cursor-default"
                        >
                            {[
                                "Contractor (Providing and Fixing)",
                                "Labour Contractor",
                                "Material Supplier",
                                "Consultant",
                            ].map((name) => (
                                <li
                                    onClick={() => handleSelectedVendorType(name)}
                                    className="py-1 hover:bg-[#C3D5FE] hover:rounded-md px-6 ml-2 mr-2"
                                >
                                    {name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            {/* Search by Trade or Business */}
            <div
                onMouseEnter={handleTradeOrBusinessHover}
                onMouseLeave={handleTradeOrBusinessNonHover}
                onClick={handleTradeDropdown}
                className="relative flex items-center"
            // style={{ backgroundColor: tradeBgColor }}
            >
                {showLeftDivider &&
                    selectedSection !== "trade" &&
                    selectedSection !== "vendor" && (
                        <div className="pb-1 absolute  left-0" style={{ width: '0.5px', height: '32px', backgroundColor: '#BFBFBF' }}></div>)}
                <div
                    className={`flex items-center hover:rounded-full md:w-[320px] md:h-[49px] lg:w-[417px] lg:h-[59px] ${selectedSection == "trade"
                        ? "bg-white rounded-full"
                        : "hover:bg-[#E1E1E1]"
                        }`}
                >
                    <img
                        src="https://storagereponeevaydevcdn.blob.core.windows.net/business/N2_searchbar_magnifying_glass.svg"
                        alt="Search"
                        className="w-4 h-4 md:ml-3 lg:ml-[16px]"
                    />
                    <input
                        type="text"
                        className="h-6 md:w-[320px] lg:w-[370px] text-sm font-medium border-none focus:ring-transparent bg-transparent px-[12px] placeholder:font-medium placeholder:text-base overflow-x-auto text-[#787878] placeholder-[#787878]"
                        value={selectedTradeOrBusiness}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setSelectedTradeOrBusiness(e.target.value)}
                        placeholder={searchInputPlaceHolder}
                    />
                    {/* <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/searchbar_arrow.svg" alt="" className="mx-2" /> */}

                    {!selectedTradeOrBusiness.trim() && error && (
                        <Tooltip
                            title="This field is required"
                            arrow
                            placement="top-end"
                            open
                            TransitionComponent={Zoom}
                            TransitionProps={{ timeout: 500 }}
                        >
                            <img
                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/circle_exclamation_solid_org.svg"
                                alt="Error"
                                className="absolute right-2 top-4 w-4 h-4"
                            />
                        </Tooltip>
                    )}
                    {/* {tradeClicked && selectedVendorType !== "" && (
                        <ul
                            ref={dropdownRef}
                            className="absolute z-50 bg-[#fff] py-4 md:w-[230px] lg:w-[270px] rounded-3xl md:top-[50px] lg:top-[60px] text-sm font-medium shadow cursor-default"
                        >
                            <li className="pl-6 pr-14 ml-2 mr-2 mb-2 text-xs font-semibold">
                                You can search for 
                            </li>
                            {mostPopularSearchesData[selectedVendorType]?.map((name) => (
                                <li
                                    onClick={() => handleSelectedTradeOrBusiness(name)}
                                    className="py-1 hover:bg-[#C3D5FE] hover:rounded-md pl-6 pr-14 ml-2 mr-2"
                                >
                                    {name}
                                </li>
                            ))}
                        </ul>
                    )} */}
                </div>
            </div>

            {/* Region */}
            <div
                onMouseEnter={handleRegionHover}
                onMouseLeave={handleRegionNonHover}
                onClick={handleRegionDropdown}
                className="relative"
                // style={{ backgroundColor: regionBgColor, borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}
                style={{
                    borderTopRightRadius: "50px",
                    borderBottomRightRadius: "50px",
                }}
            >
                {showRightDivider &&
                    selectedSection !== "trade" &&
                    selectedSection !== "region" && (
                        <div className="absolute lg:mt-3 md:mt-2  left-0" style={{ width: '0.5px', height: '32px', backgroundColor: '#BFBFBF' }}></div>
                    )}
                <div
                    className={`flex items-center rounded-full hover:rounded-full md:h-[49px] lg:h-[59px] ${selectedSection == "region" ? "bg-white" : "hover:bg-[#E1E1E1]"
                        }`}
                >
                    <img
                        src="https://storagereponeevaydevcdn.blob.core.windows.net/business/searchbar_location_nn.svg"
                        alt="Location"
                        className="w-5 h-5 ml-2.5"
                    />
                    <input
                        type="text"
                        className="h-6 md:w-[96px] lg:w-[104px] text-sm font-medium border-none focus:ring-transparent bg-transparent px-[12px] placeholder:font-medium text-[#787878] placeholder:text-base overflow-x-auto placeholder-[#787878]"
                        value={selectedRegion}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => handleChangeRegionValue(e)}
                        placeholder="City"
                    />
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/searchbar_arrow.svg" alt="" className="mx-2" />
                    {!selectedRegion.trim() && error && (
                        <Tooltip
                            title="This field is required"
                            arrow
                            open
                            placement="top-end"
                            TransitionComponent={Zoom}
                            TransitionProps={{ timeout: 500 }}
                        >
                            <img
                                src="https://storagereponeevaydevcdn.blob.core.windows.net/business/circle_exclamation_solid_org.svg"
                                alt="Error"
                                className="absolute right-[100px] mr-1 top-4 w-4 h-4"
                            />
                        </Tooltip>
                    )}
                    {regionClicked && (

                        <ul
                            ref={dropdownRef}
                            className="absolute z-50 max-h-48 overflow-auto bg-[#fff] py-4 md:w-[200px] lg:w-[215px] rounded-3xl md:top-[50px] lg:top-[60px] text-sm font-medium shadow cursor-default"
                        >
                            {selectedRegion == "" && <><li className="pl-4 pb-2 text-[#787878]">Popular Cities</li>
                                {mostPopularSearchCityData?.map((city) => (

                                    <div className="flex items-center pl-4">
                                        <NorthWestIcon sx={{ color: '#CCCCCC', fontSize: 'small' }} />
                                        <li
                                            onClick={() => handleSelectedRegion(city)}
                                            className="py-1 hover:bg-[#C3D5FE] hover:rounded-md  w-full mr-4 pl-2"
                                        >
                                            {city}
                                        </li>
                                    </div>

                                ))}</>}
                            <li className="pl-4 py-2 text-[#787878]">Other Cities</li>
                            {regions?.map((city) => (

                                <div className="flex items-center pl-4">
                                    <NorthWestIcon sx={{ color: '#CCCCCC', fontSize: 'small' }} />
                                    <li
                                        onClick={() => handleSelectedRegion(city)}
                                        className="py-1 hover:bg-[#C3D5FE] hover:rounded-md  w-full mr-4 pl-2"
                                    >
                                        {city}
                                    </li>
                                </div>

                            ))}
                            {regions?.length === 0 && (
                                <li
                                    // onClick={() => handleSelectedRegion(city)}
                                    className="py-1 pl-6 pr-4 ml-2 mr-2 text-gray-600"
                                >
                                    No city found
                                </li>
                            )}
                        </ul>
                    )}
                    {/* Search button */}
                    <button
                        onClick={(e) => {
                            e.stopPropagation(); // Stop the event from bubbling up
                            handleSearchClick();
                        }}
                        className="flex justify-center items-center md:w-[40px] md:h-[40px] lg:w-[49px] lg:h-[49px] mr-1 rounded-full bg-[#F16500] text-white hover:bg-orange-500 transition duration-300 "
                    >
                        <img
                            src="https://storagereponeevaydevcdn.blob.core.windows.net/business/searchbar_white.svg"
                            alt="Search"
                            className="lg:w-[23px] lg:h-[23px] md:w-[18px] md:h-[18px] "
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};


export default NewSearchbar
