import React from 'react'

const OtherFeatures = () => {

    return (
        <div className='mx-6'>
            <div className='bg-orange-300 w-[54px] h-[8px] md:w-[33px] md:h-[8px] xl:w-[56px] xl:h-[10px] mx-auto align-middle'></div>
            <h3 className='xl:text-5xl text-[28px] md:text-[32px] font-bold text-center pt-[10px] pb-[60px] md:pt-[10px] md:pb-[87px] xl:pt-[30px] xl:pb-[97px]'>Other Features</h3>
            {/* <div className='grid grid-cols-2 md:gap-6 xl:gap-y-8 xl:gap-x-14'>
                <p className='bg-[#EAECFE] md:text-xl xl:text-lg text-[#424242] md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Unlimited RFQ invitations</p>
                <p className='bg-[#EAECFE] md:text-xl xl:text-lg text-[#424242] md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Access largest contractor Network</p>
                <p className='bg-[#EAECFE] md:text-xl xl:text-lg text-[#424242] md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Dedicated Customer Support</p>
                <p className='bg-[#EAECFE] md:text-xl xl:text-lg text-[#424242] md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Vendor Relationship Tracking</p>
                <p className='bg-[#EAECFE] md:text-xl xl:text-lg text-[#424242] md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Branded RFQ Invites</p>
                <p className='bg-[#EAECFE] md:text-xl xl:text-lg text-[#424242] md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Smart Search & Instant RFQ lists</p>
            </div> */}

            <div className='flex flex-col md:flex-row justify-center items-center  space-y-4 md:space-y-0 xl:space-x-14 md:space-x-6'>
                <div className='space-y-4 xl:space-y-8 md:space-y-6'>
                    <p className='bg-[#EAECFE] text-center text-xl xl:text-lg text-[#424242] w-[295px] h-full py-3 px-4 md:px-0 md:py-0 md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Unlimited RFQ invitations</p>
                    <p className='bg-[#EAECFE] text-center text-xl xl:text-lg text-[#424242] w-[295px] h-full py-3 px-4 md:px-0 md:py-0 md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Access largest contractor Network</p>
                    <p className='bg-[#EAECFE] text-center text-xl xl:text-lg text-[#424242] w-[295px] h-full py-3 px-4 md:px-0 md:py-0 md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Dedicated Customer Support</p>
                </div>
                <div className='space-y-4 xl:space-y-8 md:space-y-6'>
                    <p className='bg-[#EAECFE] text-center text-xl xl:text-lg text-[#424242] w-[295px] h-full py-3 px-4 md:px-0 md:py-0 md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Vendor Relationship Tracking</p>
                    <p className='bg-[#EAECFE] text-center text-xl xl:text-lg text-[#424242] w-[295px] h-full py-3 px-4 md:px-0 md:py-0 md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Branded RFQ Invites</p>
                    <p className='bg-[#EAECFE] text-center text-xl xl:text-lg text-[#424242] w-[295px] h-full py-3 px-4 md:px-0 md:py-0 md:w-[336px] md:h-[54px] xl:w-[398px] xl:h-[56px] flex justify-center items-center'>Smart Search & Instant RFQ lists</p>
                </div>
            </div>

            {/* horizontal line */}
            <div className='xl:hidden h-[2px]  mt-[60px] bg-[#CECECE]'></div>
        </div>
    )
}

export default OtherFeatures
