import React from 'react'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'

const HeroSection = () => {
    const navigate = useNavigate()
    const handleDemoClick=()=>{
        navigate('/get-started')
        window.scroll(0,0)
    }
    return (
        <div className=" bg-cover bg-[#eeeeee] bg-center min-h-[749px] md:max-h-[649px] xl:h-[854px]" style={{ backgroundImage: "url('https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_hero_img.svg')" }}>
            {/* div for left to right shadow */}
            <div className="bg-gradient-to-r from-[#0F0906EB] from-40% md:from-25% to-[#d9d9d900] h-[854px] pb-12">
                {/* div for top to bottom shadow */}
                <div className="px-4 md:px-0 md:bg-gradient-to-b from-[#00000080] from-70% md:from-40% md:via-transparent to-transparent h-full">

                    {/* navbar */}
                    <Navbar />

                    <div className='max-w-[1280px] mx-auto md:pl-[24px] xl:pl-16 mt-[100px] xl:mt-[80px] block'>
                        <p className='hidden md:block uppercase text-white text-xs md:text-sm xl:text-base'>Helping companies control their vendor data & rfq processes  </p>
                        <p className='md:hidden uppercase text-white font-normal text-xs '>Helping companies control their<br/> vendor data & rfq processes  </p>

                        <p className="hidden md:block xl:text-[64px] text-[40px] md:text-[48px] font-bold text-[#FFF9F4]">Neevay<span className='text-[#FAB576] italic'>Pro</span> is a better way <br />to search & manage vendors</p>
                        <p className="text-[40px] md:hidden font-bold mt-2 text-[#FFF9F4]">Neevay<span className='text-[#FAB576] italic'>Pro</span> is a better way to search & manage vendors</p>

                        <p className='text-[#DDC5B0] hidden xl:block text-lg pt-[20px]'>Discover new vendors & mitigate risks for your big construction projects. <br />Negotiate better prices while bringing transparency across your organization.</p>
                        <p className='text-white hidden md:block xl:hidden text-xl pt-[20px]'>Discover new vendors & mitigate risks for your big<br /> construction projects. Negotiate better prices while<br /> bringing transparency across your organization.</p>
                        <p className='text-white md:hidden text-lg pt-[40px]'>Discover new vendors & mitigate risks<br /> for your big construction projects.<br /> Negotiate better prices while bringing<br /> transparency across your organization.</p>

                        <div className='flex md:flex-row flex-col-reverse md:space-x-8 mt-[40px] md:mt-[64px] xl:mt-[40px]'>
                            {/* <button className='mx-auto md:mx-0 mt-4 md:mt-0 p-2 bg-transparent text-[#FFEFE1] border-2 border-[#FFEFE1] w-[220px] h-[48px] md:h-[48px] md:w-[188px] xl:h-[55px] xl:w-[220px]'>Why Neevay ?</button> */}
                            <button onClick={handleDemoClick} className=' flex justify-center mx-auto md:mx-0 items-center mt-8 md:mt-0 bg-black text-[#E6E6E6] font-semibold border-l-8 border-orange-500 w-[220px] h-[48px] md:h-[48px] md:w-[188px] xl:h-[55px] xl:w-[220px]'>Book a Demo
                                <img className='pl-5' src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Arrow_template.svg" alt="" />
                            </button>
                        </div>
                    </div>
                    {/* <div className='absolute bg-[#FF9431] h-[298px] w-[260px] right-20 bottom-0'></div> */}
                </div>
            </div>
        </div>
    )
}

export default HeroSection
