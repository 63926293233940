import React from 'react';
import { useNavigate } from 'react-router-dom';

const TryNeevayPro = () => {
    const navigate = useNavigate()
    const handleDemoClick=()=>{
        navigate('/get-started')
        window.scroll(0,0)
    }
    return (
        <div className="py-[154px] bg-right xl:h-[556px] bg-[url('https://storagereponeevaydevcdn.blob.core.windows.net/business/try_neevay_pro.svg')] bg-cover ">
            <div className="flex flex-col items-center ">
                <p className='text-[40px] md:text-[42px] xl:text-[48px] mx-8 md:mx-0 text-center font-bold text-[#2A2A2A]'>
                    Try Neevay <span className='text-[#FD6600] italic'>Pro</span> with your team
                </p>
                <button onClick={handleDemoClick} className='flex justify-center items-center text-lg md:text-base bg-black text-[#E6E6E6] font-semibold xl:border-l-8 xl:border-orange-500 w-[245px] md:w-[239px] h-[48px] xl:w-[275px] xl:h-[64px] mb-[26px] mt-[64px] md:mt-[77px]'>
                    Book a Demo
                    <img className='pl-5' src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Arrow_template.svg" alt="" />
                </button>
                <p className='text-lg md:text-base text-[#666682]'>
                    Need an account? <button onClick={handleDemoClick} className='text-blue-500 underline'>Create one now</button>
                </p>
            </div>
        </div>
    );
}

export default TryNeevayPro;
