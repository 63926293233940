import React from 'react';
import Navbar from './BuyerComponents/Navbar';
import Hero from './BuyerComponents/Hero';
import Logos from './BuyerComponents/Logos';
import EasySteps from './BuyerComponents/EasySteps';
import BenefitsOfNeevay from './BuyerComponents/BenefitsOfNeevay';
import FindNextContractor from './BuyerComponents/FindNextContractor';
import Testimonials from './BuyerComponents/Testimonials';
import RFQmngt from './BuyerComponents/RFQmngt';
import LatestBlog from './BuyerComponents/LatestBlog';
import Footer from './BuyerComponents/Footer';
import './BuyerComponents/custom.css';
import { useRef } from 'react';
import LatestBlogForMobile from './BuyerComponents/LatestBlogsForMobile';
import { CompanySlider } from 'views/Home 3.0/components';
import CompanyLogoSlider from './BuyerComponents/CompanyLogoSlider';
import Cards from './BuyerComponents/Cards';
import CorporatePageMain from './CorporatePages/CorporatePageMain';

const Homepage = () => {
    const homeRef = useRef(null);
    const scrollToHome = () => {
        homeRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <div style={{ fontFamily: "trade-gothic-lt" }}>

            <CorporatePageMain/>
            
        </div>
    );
};

export default Homepage;
