import React, { useState } from 'react';
import CompanyLogoSlider from './CompanyLogoSlider';

const testimonials = [
    {
        text: "Using Neevay portal has been a game-changer for my team. Neevay portal simplified our search for contractors, suppliers, manufacturers and labor contractors in different regions",
        name: "Yogesh Mewani",
        company: "Ganesha Construction",
        imgSrc: "https://storagereponeevaydevcdn.blob.core.windows.net/business/yogesh_mewani.svg",
    },
    {
        text: "As a s steel seller on Neevay portal has really helped our business. Easy connections with buyers and a straightforward approach make it a valuable resource for any steel seller. Highly recommended!",
        name: "Gokul Tiwari",
        company: "AMP IMPEX",
        imgSrc: "https://storagereponeevaydevcdn.blob.core.windows.net/business/gokul_tiwari.svg",
    },
];

const TrustedByIndustry = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [imageLoaded, setImageLoaded] = useState(false);

    const nextSlide = () => {
        setImageLoaded(false); // Reset loading state
        setCurrentSlide((currentSlide + 1) % testimonials.length);
    };

    const prevSlide = () => {
        setImageLoaded(false); // Reset loading state
        setCurrentSlide((currentSlide - 1 + testimonials.length) % testimonials.length);
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <div className='py-[80px] xl:py-[160px]'>
            <div className='bg-orange-300 w-[54px] h-[8px] xl:w-[56px] xl:h-[10px] md:w-[33px] md:h-[8px] mx-auto align-middle'></div>
            <h3 className='xl:text-5xl md:text-[32px] text-[28px] font-bold text-center pt-[10px] pb-[60px] md:pb-[56px] xl:pt-[30px] xl:pb-[97px]'>
                Trusted by industry leaders
            </h3>
            <div className="flex flex-col justify-center mx-auto xl:pt-16 max-w-[1280px]">
                <div className='flex justify-center items-center mb-8'>
                    <button
                        onClick={prevSlide}
                        className='w-[40px] h-[40px] md:w-[50px] md:h-[50px] flex items-center justify-center rounded-full bg-white border border-[#A8A894]'>
                        <img src='https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage4_left_arrow.svg' alt='previous' className='h-[23px] w-[10px]' />
                    </button>
                    <div style={{ boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)' }} className="flex justify-center items-center mx-4 md:mx-8">
                        <div className="flex flex-col-reverse md:flex-row justify-between items-center h-[474px] w-[218px] md:h-[246px] md:w-[558px] xl:w-[872px] xl:h-[336px]">
                            <div className="flex flex-col justify-center p-4 md:py-0 md:px-[20px] xl:px-10 md:w-[50%] xl:w-1/2 h-[324px] md:h-full">
                                <p className='md:text-sm xl:text-xl text-[#393917]'>{testimonials[currentSlide].text} </p>
                                <p className='md:text-sm xl:text-xl text-[#35351A] font-bold pt-[18px] md:pt-[20px] xl:pt-[40px]'>{testimonials[currentSlide].name}</p>
                                <p className='md:text-sm xl:text-xl text-[#626262]'>{testimonials[currentSlide].company}</p>
                            </div>
                            <div className="md:w-[50%] h-[324px] md:h-full flex items-center justify-center bg-white">
                                {!imageLoaded && <div className="h-full w-full bg-white" />}
                                <img
                                    src={testimonials[currentSlide].imgSrc}
                                    alt="img"
                                   
                                    className={`md:h-full object-cover w-[218px] h-[181px] md:w-full xl:w-[438px] ${imageLoaded ? 'block' : 'hidden'}`}
                                    onLoad={handleImageLoad}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={nextSlide}
                        className='w-[40px] h-[40px] md:w-[50px] md:h-[50px] flex items-center justify-center rounded-full bg-white border border-[#A8A894]'>
                        <img src='https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage4_right_arrow.svg' alt='next' className='h-[23px] w-[10px]' />
                    </button>
                </div>
            </div>
            <div className='xl:px-48 md:px-28 lg:px-44 max-w-[1280px] mx-auto'>
                <CompanyLogoSlider />
            </div>
        </div>
    );
};

export default TrustedByIndustry;
