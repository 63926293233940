import React, { useState } from 'react';

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Does Neevay share my private vendor list with competitors?",
      answer: "No we do not share your vendor list with anyone. In fact, all your data is secure within your own private cloud."
    },
    {
      question: "How is the vendor contact information kept up to date?",
      answer: "Neevay's team periodically checks for that info on behalf of our Neevay Pro customers. And updates that info into the portal."
    },
    {
      question: "How are the deals closed with the vendors?",
      answer: "Neevay is facilitating the match-making between the vendors and the buyers. You close the deals offline."
    },
    {
      question: "Where is my information stored when my company joins the network?",
      answer: "Neevay takes security very seriously. Your information is stored securely in a private cloud solely dedicated to your company."
    }
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className='bg-[#FFF8F3] px-4 md:px-6 py-[80px] md:py-[100px] xl:px-[155px] xl:py-[48px]'>
      <div className='max-w-[1024px] mx-auto'>
        <h3 className='font-bold text-[32px] xl:text-5xl pb-[68px] md:pb-[60px] xl:pb-[68px]'>FAQs</h3>
        <div className='w-full h-[1px] bg-[#B6B6B6]'></div>
        {faqs.map((faq, index) => (
          <div key={index}>
            <div className='xl:hover:bg-[#f3f4f6]'>
              <div onClick={() => handleToggle(index)} className='flex w-full justify-between py-8 md:px-4 cursor-pointer'>
                <p className='md:text-xl text-lg w-[85%] '>{faq.question}</p>
                <button className='flex justify-center items-center w-[45px] h-[45px] rounded-full border border-gray-400'>
                  <img
                    src="https://storagereponeevaydevcdn.blob.core.windows.net/business/downward_arrow.svg"
                    alt="Toggle FAQ"
                    className={`transition-transform duration-300 ${activeIndex === index ? 'rotate-180' : ''}`}
                  />
                </button>
              </div>
              {activeIndex === index && (
                <>
                  <p className='hidden md:block w-[80%] md:text-xl text-lg pb-12 md:px-4'>
                    {faq.answer.split('<br />').map((line, i) => <span key={i}>{line}<br /></span>)}
                  </p>
                  <p className='text-lg pb-12 md:hidden'>
                    {faq.answer.replace('<br />', ' ')}
                  </p>
                </>
              )}
            </div>
            <div className='w-full h-[1px] bg-[#B6B6B6]'></div>
          </div>
        ))}
        {/* <div className='w-full h-[0.20px] bg-[#B6B6B6]'></div> */}
        <hr />
      </div>
    </div>
  );
};

export default FAQs;
