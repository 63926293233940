import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CompanyLogoSlider = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const settings = {
        infinite: true,
        slidesToShow: window.innerWidth >= 768 ? 4 : 3, // Adjust based on screen size
        slidesToScroll: 1,
        autoplay: true,
        speed: 8000,
        autoplaySpeed: 6000,
        cssEase: "linear",
        arrows: false,
    };

    const companyLogoImages = [
        "/business/homepage_floating_logos_aquara.svg",
        "/business/homepage_floating_logos_basil.svg",
        "/business/homepage_floating_logos_gajkesaristeel.svg",
        "/business/homepage_floating_logos_nyati.svg",
        "/business/homepage_floating_logos_oxy.svg",
        "/business/homepage_floating_logos_reliance.svg",
        "/business/homepage_floating_logos_skyi.svg",
        "/business/homepage_floating_logos_thermax.svg",
        "/business/homepage_floating_logos_vascon.svg",
        "/business/homepage_floating_logos_vj.svg"
    ];

    return (
        <div className="py-7">
            <Slider {...settings}>
                {companyLogoImages.map((imgUrl, index) => (
                    <img
                        key={index}
                        className="h-11 mx-auto"
                        alt="logo"
                        src={`${BASE_URL}/assets${imgUrl}`}
                    />
                ))}
            </Slider>
        </div>
    );
};

export default CompanyLogoSlider;
