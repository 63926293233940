import companyDirectoryService from "services/companyDirectoryService";

export const COMPANY_DIR_FETCH_VENDORS = "@company-dir/fetch/vendor";

export const COMPANY_DIR_ADD_VENDORS = "@company-dir/add/vendor";
export const COMPANY_DIR_ADD_VENDORS_SUCCESS =
  "@company-dir/add/vendor/success";
export const COMPANY_DIR_ADD_VENDORS_FAILURE =
  "@company-dir/add/vendor/failure";

export const COMPANY_DIR_EDIT_VENDORS = "@company-dir/edit/vendor";
export const COMPANY_DIR_EDIT_VENDORS_SUCCESS =
  "@company-dir/edit/vendor/success";
export const COMPANY_DIR_DELETE_VENDORS_SUCCESS =
  "@company-dir/delete/vendor/success";
export const COMPANY_DIR_EDIT_VENDORS_FAILURE =
  "@company-dir/edit/vendor/failure";

export const COMPANY_DIR_SEARCH_VENDORS = "@company-dir/search/vendor";
export const COMPANY_DIR_SEARCH_VENDORS_SUCCESS =
  "@company-dir/search/vendor-success";
export const COMPANY_DIR_SEARCH_VENDORS_FAILURE =
  "@company-dir/search/vendor-failure";

export const COMPANY_DIR_FILTER_VENDOR = "@company-dir/filter/vendor";
export const COMPANY_DIR_FILTER_VENDOR_SUCCESS =
  "@company-dir/filter/vendor-success";
export const COMPANY_DIR_FILTER_VENDOR_FAILURE =
  "@company-dir/filter/vendor-failure";

export const COMPANY_DIR_ADD_TAGS_SUCCESS = "@company-dir/add/tag-success";
export const COMPANY_DIR_ADD_TAGS_FAILURE = "@company-dir/add/tag-failure";

export const COMPANY_DIR_CLEAR_FILTER = "@company-dir/clear/filters";

export const ADD_VENDOR_IN_CD_FROM_NN = "@company-dir/add/vendor";
export const ADD_VENDOR_IN_CD_FROM_NN_SUCCESS =
  "@company-dir/add/vendor-success";
export const ADD_VENDOR_IN_CD_FROM_NN_FAILURE =
  "@company-dir/add/vendor-failure";

export function fetchCompanyDirectoryVendors({ entityId }) {
  return async (dispatch) => {
    try {
      dispatch({ type: COMPANY_DIR_FETCH_VENDORS });
      // Fetch data from the service
      const searchKey = "";
      const vendors = await companyDirectoryService.searchVendor(
        searchKey,
        1,
        entityId
      );

      // Log the fetched data
      // console.log("This is vendor company directory data: ", vendors);

      // Dispatch success action with the fetched data
      dispatch({
        type: COMPANY_DIR_SEARCH_VENDORS_SUCCESS,
        payload: {
          vendors,
        },
      });
    } catch (error) {
      // Dispatch failure action
      dispatch({ type: COMPANY_DIR_SEARCH_VENDORS_FAILURE });

      // Rethrow the error to be caught by the calling code
      throw error;
    }
  };
}

export function searchCompanyDirectoryVendor({
  searchKey,
  pageNum = 1,
  entityId,
}) {
  return async (dispatch) => {
    try {
      // Set loading state
      dispatch({
        type: COMPANY_DIR_SEARCH_VENDORS,
        payload: {
          searchKey,
        },
      });

      // Fetch data from the service
      const vendors = await companyDirectoryService.searchVendor(
        searchKey,
        pageNum,
        entityId
      );

      // Log the fetched data
      // console.log("This is vendor company directory data: ", vendors);

      // Dispatch success action with the fetched data
      dispatch({
        type: COMPANY_DIR_SEARCH_VENDORS_SUCCESS,
        payload: {
          vendors,
        },
      });
    } catch (error) {
      // Dispatch failure action
      dispatch({ type: COMPANY_DIR_SEARCH_VENDORS_FAILURE });

      // Rethrow the error to be caught by the calling code
      throw error;
    }
  };
}

export function filterCompanyDirectory({
  filterData,
  searchKey,
  pageNum = 1,
  userId,
  entityId,
}) {
  return async (dispatch) => {
    try {
      // Set loading state
      const appliedFilters = {};

      for (const key in filterData) {
        if (
          filterData[key] !== null &&
          filterData[key] !== undefined &&
          filterData[key] !== "" &&
          !(Array.isArray(filterData[key]) && filterData[key].length === 0)
        ) {
          appliedFilters[key] = filterData[key];
        }
      }
      dispatch({
        type: COMPANY_DIR_FILTER_VENDOR,
        payload: { filterData: appliedFilters, searchKey },
      });
      const annualTurnOverFacetString = {
        "Less than 40 Lacs": ["Rs. 0 to 40 lakhs"],
        "Greater than 40 Lacs": [
          "Rs. 40 lakhs to 1.5 Cr.",
          "Rs. 1.5 Cr. to 5 Cr.",
          "Rs. 5 Cr. to 25 Cr.",
          "Rs. 25 Cr. to 100 Cr.",
          "Rs. 100 Cr. to 500 Cr.",
          "Rs. 500 Cr. and above",
        ],

        "Greater than 1.5 Crores": [
          "Rs. 1.5 Cr. to 5 Cr.",
          "Rs. 5 Cr. to 25 Cr.",
          "Rs. 25 Cr. to 100 Cr.",
          "Rs. 100 Cr. to 500 Cr.",
          "Rs. 500 Cr. and above",
        ],

        "Greater than 5 Crores": [
          "Rs. 5 Cr. to 25 Cr.",
          "Rs. 25 Cr. to 100 Cr.",
          "Rs. 100 Cr. to 500 Cr.",
          "Rs. 500 Cr. and above",
        ],
        "Greater than 25 Crores": [
          "Rs. 25 Cr. to 100 Cr.",
          "Rs. 100 Cr. to 500 Cr.",
          "Rs. 500 Cr. and above",
        ],
        "Greater than 100 Crores": [
          "Rs. 100 Cr. to 500 Cr.",
          "Rs. 500 Cr. and above",
        ],
        "Greater than 500 Crores": ["Rs. 500 Cr. and above"],
      };
      const modifyFilterData = appliedFilters["gstTurnOverRange"]
        ? {
            ...appliedFilters,
            gstTurnOverRange:
              annualTurnOverFacetString[appliedFilters["gstTurnOverRange"]],
          }
        : appliedFilters;
      // Fetch data from the service
      const vendors = await companyDirectoryService.filterVendor({
        pageNum,
        filterData: modifyFilterData,
        searchKey,
        userId,
        entityId,
      });

      // Log the fetched data
      // console.log("This is vendor company directory data: ", vendors);

      // Dispatch success action with the fetched data
      dispatch({
        type: COMPANY_DIR_FILTER_VENDOR_SUCCESS,
        payload: {
          vendors,
        },
      });
    } catch (error) {
      // Dispatch failure action
      dispatch({ type: COMPANY_DIR_FILTER_VENDOR_FAILURE });

      // Rethrow the error to be caught by the calling code
      throw error;
    }
  };
}

export function addCompanyTags(payload) {
  return async (dispatch) => {
    try {
      const data = await companyDirectoryService.addTags(payload);
      dispatch({ type: COMPANY_DIR_ADD_TAGS_SUCCESS, payload: data?.payload });
      return data;
    } catch (error) {
      dispatch({ type: COMPANY_DIR_ADD_TAGS_FAILURE });
      return error.response.data;
    }
  };
}
export function removeCompanyTags(payload) {
  return async (dispatch) => {
    try {
      const data = await companyDirectoryService.removeTags(payload);
      dispatch({ type: COMPANY_DIR_ADD_TAGS_SUCCESS, payload: data?.payload });
      return data;
    } catch (error) {
      dispatch({ type: COMPANY_DIR_ADD_TAGS_FAILURE });
      return error.response.data;
    }
  };
}
export function clearCompanyDirFilters() {
  return async (dispatch) => {
    dispatch({ type: COMPANY_DIR_CLEAR_FILTER });
  };
}

export function addVendorInCompanyDirectory(payload) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_VENDOR_IN_CD_FROM_NN });
      const data =
        await companyDirectoryService.addVendorsFromNeevayNetworkWithTags(
          payload
        );
      dispatch({ type: ADD_VENDOR_IN_CD_FROM_NN_SUCCESS });
      return data;
    } catch (error) {
      dispatch({ type: ADD_VENDOR_IN_CD_FROM_NN_FAILURE });
      return error.response.data;
    }
  };
}
