import React from 'react'
import HeroSection from './HeroSection'
import AdditionalIncome from './SaveMore'
import HowItWorks from './HowItWorks'
import CorporateTestimonials from './CorporateTestimonials'
import OtherFeatures from './OtherFeatures'
import TrustedByIndustry from './TrustedByIndustry'
import ExclusiveSupport from './ExclusiveSupport'
import FAQs from './FAQs'
import TryNeevayPro from './TryNeevayPro'
import Footer from '../BuyerComponents/Footer'
import SaveMore from './SaveMore'

const CorporatePageMain = () => {
  return (
    <div style={{ fontFamily: "trade-gothic-lt" }}>
      <HeroSection/>
      <SaveMore/>
      <HowItWorks/>
      <OtherFeatures/>
      <TrustedByIndustry/>
      <ExclusiveSupport/>
      <FAQs/>
      <TryNeevayPro/>
      <Footer/>
    </div>
  )
}

export default CorporatePageMain
