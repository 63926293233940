import { searchCitiesAction } from "actions/regionsActions";
import { SEARCH, SEARCH_FILTERED, searchByPage } from "actions/searchActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MobileSearch = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cityOptions = useSelector((state) => state.regions.cities);

  const [openCityDropdown, setCityDropdown] = useState(false);
  const [formData, setFormData] = useState({
    vendorType: "",
    search: "",
    city: "",
  });
  const [errors, setErrors] = useState({});
  // Example options
  const [filteredCityOptions, setFilteredCityOptions] = useState([
    "Pune",
    "Mumbai",
    "Delhi",
    "Bengaluru",
  ]);

  const [searchInputPlaceHolder, setSearchInputPlaceHolder] = useState("Search vendor services, material, etc.");


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: value ? "" : "This field is required",
    });

    if (name === "city") {
      const filteredCity = cityOptions.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCityOptions(filteredCity?.slice(0, 5));
    }

    // Set placeholder based on value
  switch (value) {
    case "Material Supplier":
      setSearchInputPlaceHolder("What Type of Material?");
      break;
    case "Labour Contractor":
      setSearchInputPlaceHolder("What type of Skill?");
      break;
    case "Contractor (Providing and Fixing)":
    case "Consultant":
      setSearchInputPlaceHolder("What type of Work?");
      break;
  }
  };

  const handleCitySelect = (city) => {
    setFormData({
      ...formData,
      city: city,
    });
    // setFilteredCityOptions([]);
    setErrors({
      ...errors,
      city: "",
    });
  };

  const handleSubmit = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = "This field is required";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      console.log("Form submitted:", formData);
      handleSearch();
    }
  };
  const handleSearch = () => {
    const modifiedSearchKeyWord = formData?.search?.replace(/ /g, "-");
    const regionValue = formData?.city; // Example region value
    const modifiedVendorType = formData?.vendorType?.replace(/ /g, "-");
    navigate(
      `/search-business-category/${modifiedSearchKeyWord}/${regionValue}?Category=${modifiedVendorType}`
    );
  };

  useEffect(() => {
    dispatch(searchCitiesAction());
  }, []);

  return (
    <div
      className={`fixed inset-0 h-full z-50 flex flex-col justify-between bg-white transition-transform duration-1000 ${
        isOpen ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="p-4 pb-2">
        <button
          onClick={() => {
            setIsOpen(false);
            setFormData({
              vendorType: "",
              search: "",
              city: "",
            });
          }}
          className="w-8 h-8 p-1 mr-auto rounded-full border border-black mb-4"
        >
          <svg
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="CloseIcon"
          >
            <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
          </svg>
        </button>

        <div
          className="mb-3 border border-[#D3D3D3] p-5"
          style={{ boxShadow: "0px 4px 20px 0px #0B0B1A33" }}
        >
          <label className="block font-bold text-xl mb-2">Vendor Type</label>
          <select
            name="vendorType"
            value={formData.vendorType}
            onChange={handleInputChange}
            className={`w-full p-2 border rounded-lg ${
              errors.vendorType ? "border-red-500" : "border-gray-300"
            }`}
          >
            <option value="" disabled>
              Vendor Type
            </option>
            {[
              "Contractor (Providing and Fixing)",
              "Labour Contractor",
              "Material Supplier",
              "Consultant",
            ].map((data) => (
              <option value={data}>{data}</option>
            ))}
          </select>
          {errors.vendorType && (
            <p className="text-red-500 text-sm">{errors.vendorType}</p>
          )}
        </div>

        <div
          className="mb-3 border border-[#D3D3D3] p-5"
          style={{ boxShadow: "0px 4px 20px 0px #0B0B1A33" }}
        >
          <label className="block font-bold text-xl mb-2">Search</label>
          <input
            name="search"
            type="text"
            value={formData.search}
            onChange={handleInputChange}
            className={`w-full p-2 border rounded-lg ${
              errors.search ? "border-red-500" : "border-gray-300"
            }`}
            placeholder= {searchInputPlaceHolder}
          />
          {errors.search && (
            <p className="text-red-500 text-sm">{errors.search}</p>
          )}
        </div>

        <div
          className="mb-3 border border-[#D3D3D3] p-5"
          style={{ boxShadow: "0px 4px 20px 0px #0B0B1A33" }}
        >
          <label className="block font-bold text-xl mb-2">City</label>
          <div
            onClick={() => setCityDropdown(true)}
            className="relative w-full"
          >
            {/* <!-- SVG Icon --> */}
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="LocationOnOutlinedIcon"
              >
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7M7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9"></path>
                <circle cx="12" cy="9" r="2.5"></circle>
              </svg>
            </div>
            {/* <!-- Input Field --> */}
            <input
              name="city"
              type="text"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="City"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            />
            {/* <!-- Dropdown Icon --> */}
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg
                className={`w-5 h-5 text-gray-500 rotate-${
                  openCityDropdown ? "180" : "0"
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </div>

            {openCityDropdown && (
              <div className="absolute left-0 right-0 top-full bg-white border border-gray-300 mt-1 z-10">
                {filteredCityOptions?.length === 0 && (
                  <div className="p-2 cursor-pointer hover:bg-gray-200">
                    No results found
                  </div>
                )}
                {filteredCityOptions?.map((option) => (
                  <div
                    key={option}
                    onClick={() => {
                      handleCitySelect(option);
                      setTimeout(() => {
                        setCityDropdown(false);
                      });
                    }}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>

          {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
        </div>
      </div>

      <div
        className="flex px-4 py-5 justify-between items-center"
        style={{ boxShadow: "0px 4px 20px 0px #0B0B1A33" }}
      >
        <button
          onClick={() => setFormData({ vendorType: "", search: "", city: "" })}
          className="px-4 py-2 underline font-bold text-lg"
        >
          Clear All
        </button>
        <button
          onClick={handleSubmit}
          className="px-10 py-2 bg-black border-l-[6px] border-orange-500 text-white font-bold text-lg"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default MobileSearch;
