import React, { useState, useEffect } from 'react';

const CorporateTestimonials = () => {
  const testimonials = [
    {
      image: "https://storagereponeevaydevcdn.blob.core.windows.net/business/Testimonial_2.svg",
      quote: "“This is the best RFQ management tool<br />my team has found in quite some time.”",
      author: "MEGHA PURANIK, VASCON ENGINEERS"
    },
    {
      image: "https://storagereponeevaydevcdn.blob.core.windows.net/business/Testimonial_1.svg",
      quote: "“Neevay portal has really helped our business.Easy connections with buyers <br />and a straight forward approach makes it a valuable resource for us.”",
      author: "Gokul Tiwari, AMP IMPEX"
    }
    // Add more testimonials if needed
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setTimeout(() => {
      setCurrentTestimonial((prevTestimonial) => 
        (prevTestimonial + 1) % testimonials.length
      );
    }, 10000); // Change slide every 10 seconds

    return () => clearTimeout(interval);
  }, [currentTestimonial]);

  return (
    <div className='flex flex-col justify-center items-center'>
      <img 
        src={testimonials[currentTestimonial].image}
        alt="Testimonial" 
        className='rounded-full w-[114px] h-[114px] md:w-[121px] md:h-[121px] xl:w-[150px] xl:h-[150px]' 
      />
      <p className='text-base md:text-lg xl:text-2xl font-bold text-center text-[#27270B] md:pt-[50px] md:pb-[76px] xl:pt-[66px] pt-[29px] pb-[60px]'>
        {testimonials[currentTestimonial].quote.split('<br />').map((line, i) => <span key={i}>{line}<br /></span>)}
      </p>
      <h3 className='text-[#5B5B3D] text-xs xl:text-base font-bold uppercase'>
        {testimonials[currentTestimonial].author}
      </h3>
    </div>
  );
};

export default CorporateTestimonials;
