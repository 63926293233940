import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogInMobilePopUp from 'views/Auth/SignInSendMobileOTP/LogInMobilePopUp';
import { signout } from 'actions/authActions';

const Navbar = () => {
  const [loginModal, setLoginModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // Added state for menu toggle
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  const menuRef = useRef(null); 

  const openLoginPopup = () => {
    setLoginModal(true);
  };

  const handleCloseLogInModal = () => {
    setLoginModal(false);
  };

  const handleLogout = async () => {
    setMenuOpen(false); // Close the menu on logout
    try {
      await dispatch(signout());
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const getInitials = (name) => {
    if (!name) return '';
    const initials = name
      .split(' ')
      .map((n) => n[0])
      .join('');
    return initials.toUpperCase();
  };

  const joinVendorClicked = () => {
    navigate('/register/business');
  };

  const myBusinessClicked = () => {
    navigate('/my-business');
  };

  const myLeadsClicked = () => {
    navigate('/leads');
  };

  const handleDemoClick = () => {
    navigate('/get-started');
    window.scroll(0, 0);
  };

  useEffect(() => {
    if (userProfile?.userEntity?.entityId) {
      navigate('/rfqs');
    }
    if (userProfile !== null) {
      if (userProfile?.userId > 0 && userProfile?.name) {
        // User is already registered, no navigation needed
      } else {
        navigate('/register/user');
      }
    }
  }, [userProfile, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    // Add event listener for clicks outside
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className='flex justify-between items-center max-w-[1280px] mx-auto md:px-[24px] xl:px-16 pt-8 '>
        {/* Logo and Options */}
        <div>
          <ul className='flex justify-between md:space-x-8 xl:space-x-[36px] items-center font-semibold text-sm text-white'>
            <img
              src='https://storagereponeevaydevcdn.blob.core.windows.net/business/NEEVAY_LOGO_email.png'
              alt='Neevay'
              className='w-[104px] md:w-[97px] xl:w-[120px] h-full'
            />
          </ul>
        </div>

        {/* Login, Signup, and Profile Options */}
        <div className='flex justify-between w-fit space-x-[34px]'>
          {!userProfile ? (
            <>
              <button
                onClick={openLoginPopup}
                className='flex justify-center items-center bg-black pb-1 h-[44px] xl:h-[48px] w-[118px] text-white rounded-sm hover:bg-transparent hover:border-[2px]'
              >
                Login
              </button>

              <button
                onClick={handleDemoClick}
                className='hidden md:block px-2 pb-1 h-[44px] xl:h-[48px] w-[127px] text-white text-sm font-semibold rounded-sm border-[2px] hover:bg-white hover:text-black hover:border-none'
              >
                Book a Demo
              </button>
            </>
          ) : (
            <div className='relative flex' ref={menuRef}>
              {/* Show different options based on userType */}
              {userProfile?.userType === 'vendor' ? (
                <button
                  onClick={myBusinessClicked}
                  className=' text-[#F16500] text-sm md:text-base mr-4 md:mr-[22px] xl:pr-0 border-none font-bold underline'
                >
                  My Business
                </button>
              ) : null}

              {userProfile?.userType === 'vendor' ? (
                <button
                  onClick={myLeadsClicked}
                  className=' text-[#F16500] text-sm md:text-base mr-4 md:mr-[22px] xl:pr-0 border-none font-bold underline'
                >
                  My Leads
                </button>
              ) : null}

              <div
                className='flex items-center cursor-pointer'
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <div className='h-8 w-8 flex items-center justify-center bg-gray-500 rounded-full text-white font-semibold text-sm'>
                  {getInitials(userProfile?.name)}
                </div>
              </div>

              {/* Dropdown for user profile options */}
              {menuOpen && (
                <div className='absolute right-0 mt-10 w-20 bg-white border border-gray-200 rounded shadow-lg z-50'>
                  <button
                    onClick={handleLogout}
                    className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                  >
                    Log out
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Login Modal Popup */}
      <LogInMobilePopUp
        setOpenLogInModal={setLoginModal}
        openLogInModal={loginModal}
        handleCloseLogInModal={handleCloseLogInModal}
      />
    </div>
  );
};

export default Navbar;
