import React, { useEffect } from "react";
import Tooltip from '@mui/material/Tooltip';

const SelectedVendorsDrawerNN = ({
    openDrawer,
    handleCloseDrawer,
    selectedRows,
    setSelectedRows,
    handleAddVendorsToRfq,
    handleAddVendorToCD,
    handleOpen,
    isSliderView,
    selectedRowsLength,
}) => {

  const handleRemoveVendor = (vendorId) => {
    const updatedRows = selectedRows.filter((vendor) => vendor.supplierId !== vendorId);
    setSelectedRows(updatedRows);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  useEffect(() => {
    if (selectedRows.length === 0) {
      handleCloseDrawer();
    }
  }, [selectedRows, handleCloseDrawer]);

  return (
    <div
      className={`h-full fixed inset-0 bg-slate-400 bg-opacity-55 z-30 flex justify-end ${openDrawer ? "translate-x-0" : "translate-x-full"}`}
      onClick={handleCloseDrawer}
    >
      <div
        className={`fixed flex top-0 right-0 h-full text-black transition-transform duration-500 transform ${openDrawer ? "translate-x-0" : "translate-x-full"}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-gray-400 h-7 bg-white hover:bg-gray-200 hover:text-gray-900 p-1 shadow-xl">
          <button
            onClick={handleCloseDrawer}
            type="button"
            className="bg-transparent rounded-lg text-sm inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div
          className="shadow-xl max-h-xs z-10 min-w-[329px] overflow-y-auto overflow-x-hidden bg-white"
          tabIndex="-1"
          aria-hidden="true"
        >
          <h2 className="text-xl font-bold px-4 py-4 top-0 sticky bg-white">Selected Vendors <span className="text-gray-400"> {selectedRows?.length > 0 && `(${selectedRows?.length})`}</span></h2>
          {selectedRows.map((vendor) => (
            <div
              key={vendor.supplierId}
              className="flex justify-between items-center hover:bg-gray-100 px-5 py-1"
            >
              <Tooltip title={vendor?.businessName || ""} disableInteractive>
                <span>
                  {truncateText(vendor?.businessName, 20)}
                </span>
              </Tooltip>
              <button
                className="text-gray-600 ml-2 font-extrabold text-sm hover:text-red-800"
                onClick={() => handleRemoveVendor(vendor?.supplierId)}
              >
                &#10005;
              </button>
            </div>
          ))}
          <div className="flex flex-col gap-2 py-2 px-10 fixed w-full bottom-0 bg-white" style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
            {isSliderView ? (
              <button
                disabled={selectedRowsLength < 1}
                onClick={handleAddVendorsToRfq}
                className={`rounded-lg p-2 ${selectedRowsLength >= 1 ? "bg-primary-700 hover:bg-primary-800 text-white" : "bg-gray-300 text-gray-100"}`}
              >
                Invite Vendors
              </button>
            ) : (
              <div className="flex gap-4 justify-end">
                <Tooltip
                  title={selectedRowsLength < 1 ? "Select at least one vendor to apply tags" : ""}
                  disableInteractive
                >
                  <button
                    className={`flex gap-2 px-3 py-1 rounded-lg items-center ${selectedRowsLength >= 1 ? "bg-transparent border border-blue-700 hover:shadow-md  hover:border-blue-700 text-blue-700 font-medium" : "bg-gray-300 text-gray-100"}`}
                    onClick={handleAddVendorToCD}
                    disabled={selectedRowsLength < 1}
                  >
                    Add to Internal Vendors
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedVendorsDrawerNN;
