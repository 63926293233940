import React from 'react'
import CorporateTestimonials from './CorporateTestimonials'
import { useNavigate } from 'react-router-dom'

const HowItWorks = () => {
    const navigate = useNavigate()
    const handleDemoClick = () => {
        navigate('/get-started')
        window.scroll(0, 0)
    }

    return (
        <div className='max-w-[1280px] mx-auto space-y-[80px] md:space-y-[100px] xl:space-y-[160px] py-[80px] md:py-[100px] xl:py-[149px] px-4 md:px-[26px] xl:px-[84px]'>
            <div>
                <p className='text-[40px] xl:text-5xl font-bold text-center'>
                    <span className=' border-b-8 border-opacity-60 border-[#FD6600]'>Ho</span>w it works
                </p>
            </div>

            {/* construction network */}
            <div className='xl:flex items-center'>
                <div className='xl:w-1/2 '>
                    <p className='uppercase text-[#FD6600] text-sm md:text-base font-bold '>construction network</p>
                    <p className='xl:hidden font-bold text-2xl md:text-[32px] xl:text-[40px] pt-[20px] pb-[60px] md:pt-6 md:pb-[36px] xl:py-[40px]'>Thousands of construction vendors at your fingertips</p>
                    <p className='hidden xl:block font-bold xl:text-[40px] xl:py-[40px]'>Thousands of<br/> construction vendors<br/> at your fingertips</p>
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/purchase_managers_new.svg" alt="construction-network-img" className='md:hidden ' />
                    <p className='xl:hidden text-xl text-[#575757] mt-[60px] md:mt-0'>Access our extensive network of vendors tailored to your specific needs. We keep all their contact information current to save you time.</p>
                    <p className='hidden xl:block text-xl text-[#575757] mt-[60px] md:mt-0'>Access our extensive network of vendors<br/> tailored to your specific needs. We keep <br/>all their contact information current to<br/> save you time.</p>
                    <div className='flex md:flex-col xl:flex-row justify-center xl:justify-start items-center pt-[60px] pb-[40px] md:py-[30px] xl:pt-[73px] xl:pb-[44px]'>
                        <p className='text-5xl md:text-[64px] text-[#FD6600] font-bold'>20,000</p>
                        <p className='text-lg font-bold pt-0 md:pt-[28px] xl:pt-0 pl-2'>Vendors on the<br />Neevay Network</p>
                    </div>
                    <button onClick={handleDemoClick} className='text-lg mx-auto xl:mx-0 flex items-center md:text-xl font-bold text-[#FD6600]'>Streamline your projects today <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_Arrow.svg" alt="arrow" className='ml-4' /></button>
                </div>
                <div>
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/purchase_managers_new.svg" alt="construction-network-img" className='hidden md:block xl:hidden w-full mt-12 md:mt-20 md:mx-auto' />
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/purchase_managers_desktop_final2.svg" alt="construction-network-img" className='hidden xl:block mt-12 w-[769px] ' />

                </div>
            </div>

            {/* horizontal line */}
            <div className='h-[2px] bg-[#CECECE] xl:mx-[40px]'></div>

            {/* rfq management */}
            <div className='md:flex md:flex-col-reverse xl:flex-row items-center justify-between w-full'>
                <div>
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/rfq_management.svg" alt="rfq-mngt-img" className='md:mt-[60px] xl:mt-0 hidden xl:block' />
                </div>

                {/* for screens greater than 1280px width */}
                <div className='hidden xl:block'>
                    <p className='uppercase text-[#FD6600] text-base font-bold'>rfq management</p>
                    <p className='font-bold text-[40px] pt-[50px] pb-[38px]'>Everything you need to<br /> manage your bids.</p>
                    <p className='text-xl text-[#575757]'>Send invitations and monitor every detail of your <br />projects from one central location. Effortlessly<br />oversee each phase, from initial drawings to the <br /> final bid and everything in between.</p>
                    {/* <p className='text-base'><span className='h-2 w-2 bg-black rounded-full'></span><span className='font-bold'>Quick Invitations</span>: Dispatch invited in under 10 mins</p>
                     <p className='text-base'><span className='h-2 w-2 bg-black rounded-full'></span><span className='font-bold'>Comprehensive Project Management</span>: Oversee and track your entire project seamlessly from a single platform</p> */}
                    <ul className='list-disc list-outside pt-[35px] ml-4 space-y-[15px]'>
                        <li className='text-[#575757]'><span className='font-bold text-base text-black'>Quick Invitations</span>: Dispatch invited in under 10 mins</li>
                        <li className='text-[#575757]'><span className='font-bold text-black'>Comprehensive Project Management</span>: Oversee and<br /> track your entire project seamlessly from a single<br /> platform</li>
                    </ul>
                </div>

                {/* for mobile and tab */}
                <div className='xl:hidden block'>
                    <p className='uppercase text-[#FD6600] text-sm md:text-base font-bold'>rfq management</p>
                    <p className='font-bold text-2xl md:text-[32px] pt-[24px] pb-[36px]'>Everything you need to manage your bids.</p>
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/rfq_management.svg" alt="rfq-mngt-img" className='xl:hidden mx-auto' />
                    <p className='text-xl text-[#575757] pt-[60px]'>Send invitations and monitor every detail of your projects from one central location. Effortlessly oversee each phase, from initial drawings to the final bid and everything in between.</p>
                    {/* <p className='text-base'><span className='h-2 w-2 bg-black rounded-full'></span><span className='font-bold'>Quick Invitations</span>: Dispatch invited in under 10 mins</p>
                     <p className='text-base'><span className='h-2 w-2 bg-black rounded-full'></span><span className='font-bold'>Comprehensive Project Management</span>: Oversee and track your entire project seamlessly from a single platform</p> */}
                    <ul className='hidden md:block list-disc list-outside pt-[35px] ml-8 md:ml-4 space-y-[15px]'>
                        <li className='text-[#575757] text-xl'><span className='font-bold text-black'>Quick Invitations</span>: Dispatch invited in under 10 mins</li>
                        <li className='text-[#575757] text-xl'><span className='font-bold text-black'>Comprehensive Project Management</span>: Oversee and<br /> track your entire project seamlessly from a single<br /> platform</li>
                    </ul>
                    <ul className='md:hidden list-disc list-outside pt-[35px] ml-8 md:ml-4 space-y-[15px]'>
                        <li className='text-[#575757] text-xl'><span className='font-bold text-black'>Quick Invitations</span>: Dispatch invited in under 10 mins</li>
                        <li className='text-[#575757] text-xl'><span className='font-bold text-black'>Comprehensive Project Management</span>: Oversee and track your entire project seamlessly from a single platform</li>
                    </ul>
                </div>
            </div>

            {/* horizontal line */}
            <div className='h-[2px] bg-[#CECECE] xl:mx-[40px]'></div>

            {/* bid leveling */}
            <div>
                <p className='uppercase text-[#FD6600] text-sm md:text-base font-bold '>BID LEVELING</p>
                <p className='font-bold text-2xl md:text-[32px] pt-[24px] pb-[36px]'>Level your quotations quickly and accurately</p>
                <p className='text-xl text-[#575757]'>Compare quotations side by side, adjust values with ease, and collaborate seamlessly for faster & more efficient results.</p>
                <div className='flex flex-col xl:flex-row justify-between items-center md:pt-[60px] xl:pt-[95px]'>
                    {/* for laptop */}
                    <div className='hidden xl:block space-y-12 mr-14'>
                        <div>
                            <p className='text-xl font-bold pb-2'>Find the Best Quote</p>
                            <p className='text-xl text-[#474744]'>Automatically adjust totals as you input values. Toggle between vendors to identify the most competitive offer.</p>
                        </div>
                        <div>
                            <p className='text-xl font-bold pb-2'>Enhance transparency</p>
                            <p className='text-xl text-[#474744]'>Add notes to any line item to share information and keep your team organized.</p>
                        </div>
                        <div>
                            <p className='text-xl font-bold pb-2'>Mitigate Risks Upfront</p>
                            <p className='text-xl text-[#474744]'>Review the qualifications of subcontractors, including project limits & more.</p>
                        </div>
                    </div>
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/bid_leveling_new.svg" alt="bid-leveling-img" className='w-[712px] h-full my-[60px] md:my-0' />

                    {/* for mobile and tablet */}
                    <div className='xl:hidden space-y-12 md:pt-[30px]'>
                        <div>
                            <p className='text-xl font-bold pb-4 md:pb-6'>Find the Best Quote</p>
                            <p className='text-xl text-[#474744]'>Automatically adjust totals as you input values. Toggle between vendors to identify the most competitive offer.</p>
                        </div>
                        <div>
                            <p className='text-xl font-bold pb-4 md:pb-6'>Enhance transparency</p>
                            <p className='text-xl text-[#474744]'>Add notes to any line item to share information and keep your team organized.</p>
                        </div>
                        <div>
                            <p className='text-xl font-bold pb-4 md:pb-6'>Mitigate Risks Upfront</p>
                            <p className='text-xl text-[#474744]'>Review the qualifications of subcontractors, including project limits & more.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* horizontal line */}
            <div className='h-[2px] bg-[#CECECE] xl:mx-[40px]'></div>

            <CorporateTestimonials />

            {/* horizontal line */}
            <div className='h-[2px] bg-[#CECECE] xl:mx-[40px]'></div>

            {/* analytics & Reporting */}
            <div>
                <p className='uppercase text-[#FD6600] text-sm md:text-base font-bold '>analytics & Reporting</p>
                <p className='font-bold text-2xl md:text-[32px] xl:text-[40px] pt-[20px] pb-[60px] md:pt-[24px] md:pb-[36px] xl:pt-[50px] xl:pb-[16px]'>Empower Your Decisions with Data</p>
                <p className='text-xl text-[#575757]'>Gain unparalleled insights across your company with our comprehensive performance metrics and historical cost tracking.</p>
                <div className='xl:flex justify-between '>
                    <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/graph.svg" alt="graph-img" className='xl:w-3/4 md:w-[696px] h-full mx-auto xl:mx-0 my-[60px] md:mb-0 md:mt-[49px] shadow-lg' />
                    <div className='space-y-12 md:pt-[60px] xl:pt-[80px] xl:pl-36'>
                        <div>
                            <p className='text-xl font-bold pb-4 md:pb-2'>Company-wide analytics</p>
                            <p className='text-xl text-[#474744]'>Make smarter business decisions using our real-time analytics and detailed reports.</p>
                        </div>
                        <div>
                            <p className='text-xl font-bold pb-4 md:pb-2'>Historical cost data</p>
                            <p className='text-xl text-[#474744]'>Effortlessly maintain records with easy-to-access historical cost data.</p>
                        </div>
                        <div>
                            <p className='text-xl font-bold pb-4 md:pb-2'>Relationship tracking</p>
                            <p className='text-xl text-[#474744]'>Identify and track historical vendor performance metrics, communication history and transaction details</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* horizontal line */}
            <div className='h-[2px] bg-[#CECECE] xl:mx-[40px]'></div>

        </div>
    )
}

export default HowItWorks
