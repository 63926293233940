import {
  SEARCH,
  SEARCH_FILTERED,
  search,
  searchByPage,
} from "actions/searchActions";
import SearchCityDropDown from "components/SearchCityDropDown";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const SearchBarNew = ({ isMobileView, handleClose, isMobileSearchBar }) => {
  const mostPopularSerchesData = {
    Contractors: ["Civil", "Plumbing", "Waterproofing", "EPC", "Labour"],
    Supplier: [
      "Cement",
      "TMT Bar",
      "Conveyor",
      "Electrical",
      "Building Materials",
    ],
    Consultants: [
      "RCC",
      "Electrical",
      "Project Management",
      "MK Design",
      "Civil",
    ],
  };
  // const dropdownOptions = Object.keys(mostPopularSerchesData);
  const dropdownOptions = [
    "Contractor (Providing and Fixing)",
    "Labour Contractor",
    "Material Supplier",
    "Consultant",
  ];
  const navigate = useNavigate();
  const textRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchbarRef = useRef(null);
  const dispatch = useDispatch();
  var selectedType;
  const [serachInputClick, setSerachInputClick] = useState(false);
  const [SearchCityClick, setSearchCityClick] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchInputResionValue, setSearchInputRegionValue] = useState("");
  const [selectedVendorType, setSelectedVendorType] = useState("");
  const [customProductService, setCustomProductService] = useState("");
  const [vendorTypeOptions, setVendorTypeOptions] = useState([]);
  const [searchInputProducts, setSearchInputProducts] = useState(false);
  const [openNewTopBar, setOpenNewTopBar] = useState(false);
  const [regionDropdownVisible, setRegionDropdownVisible] = useState(false);
  const [vendorTypeError, setVendorTypeError] = useState(false);
  const [productServiceError, setProductServiceError] = useState(false);

  const regionInputRef = useRef(null);
  const productServiceInputRef = useRef(null);

  const focusNextInput = (nextRef) => {
    if (nextRef.current) {
      nextRef.current.focus();
    }
  };

  const handleClickProductServices = () => {
    setSearchInputProducts(true);
  };
  // const searchesClick = (data) => {
  //     console.log("data", data);
  //     setSearchInputValue(data);

  // };

  const handleSearchInputChange = (e) => {
    selectedType = e.target.value;
    setSearchInputValue(e.target.value);
    setSelectedVendorType(selectedType);
    if (selectedType !== " Select Vendor Type") {
      setSelectedVendorType(selectedType);
    }
    // Set the dropdown options based on the selected vendor type
    setVendorTypeOptions(mostPopularSerchesData[selectedType] || []);
    // setSearchInputProducts(selectedType !== "Select vendor type");

    if (selectedType && textRef.current) {
      // setSearchInputProducts(true);
      textRef.current.focus();
    }
  };

  const handleClickSearchInput = (selectedVendorType) => {
    setRegionDropdownVisible(false);
    setOpenNewTopBar(true);
    setSerachInputClick(true);
    setSearchCityClick(false);
    // setSearchInputProducts(!searchInputProducts)
  };

  const handleChangeProductAndServices = (e) => {
    setCustomProductService(e.target.value);
    setSearchInputProducts(false);

    setTimeout(() => {
      focusNextInput(productServiceInputRef);
    }, 0);
  };

  const handleSearchRegionInputChange = (e) => {
    const regionText = e.target.value;
    setSearchInputRegionValue(
      regionText
        ?.split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );
  };

  const handleClickSearchCity = () => {
    setSearchCityClick(true);
    setSerachInputClick(false);
    setRegionDropdownVisible(!regionDropdownVisible);
  };

  const handleClickOnRegion = (value) => {
    setSearchInputRegionValue(value);
    setSearchCityClick(false);
    setRegionDropdownVisible(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSerachInputClick(false);
    setSearchCityClick(false);
    setSearchInputRegionValue("");
    if (!openDropdown) {
      setVendorTypeError(true);
      return; // Stop further execution
    }
    if (!customProductService) {
      setProductServiceError(true);
      return; // Stop further execution
    }

    setVendorTypeError(false);
    setProductServiceError(false);
    const searchKeyWord = textRef.current.value;
    if (
      searchKeyWord &&
      searchKeyWord.length > 2 &&
      selectedVendorType !== "Select Vendor Type"
    ) {
      try {
        const modifiedSearchKeyWord = customProductService.replace(/ /g, "-"); // this is searchKeyWord

        // search action dispatch for search all result with product and services
        dispatch({
          type: SEARCH,
          payload: {
            searchKeyWord: customProductService, // Use modifiedKeyword in the payload
            searchType: "All",
          },
        });

        // this action call for search filter by category and this value is destructre same  as in reducer or action
        const facetTitle = "Category";
        const facetValue = searchKeyWord.replace(/-/g, " "); //  this is category
        const isSelected = true;
        dispatch({
          type: SEARCH_FILTERED,
          payload: { facetTitle, facetValue, isSelected },
        });
        const regionValue = searchInputResionValue
          ? searchInputResionValue.replace(/ /g, "-")
          : "Pan India";
        const filterStr = `businessProfile.businessCategory_str:("${facetValue}")`;

        // searchBy page dispatch for updating url category value when filter the category result
        const modifiedSearchKeyWor = searchKeyWord.replace(/ /g, "-"); // this is Category
        dispatch(
          searchByPage(customProductService, regionValue, filterStr, "All", 1)
        );
        navigate(
          `/search-business-category/${modifiedSearchKeyWord}/${regionValue}?Category=${modifiedSearchKeyWor}`
        );
        handleClose();
      } catch (error) {
        // console.log("SearchBar Error: ", error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission behavior
      handleSearch(e);
    }
  };

  const handleClickOutside = (event) => {
    if (
      searchbarRef.current &&
      !searchbarRef.current.contains(event.target) &&
      !dropdownRef.current?.contains(event.target) &&
      !textRef.current?.contains(event.target) &&
      !productServiceInputRef.current?.contains(event.target)
    ) {
      setSearchInputProducts(false);
      setRegionDropdownVisible(false);
    }
  };
  const openDropdown = true;
  // const openDropdown =
  //   selectedVendorType === "Contractors" ||
  //   selectedVendorType === "Supplier" ||
  //   selectedVendorType === "Consultants";

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchbarRef, dropdownRef, textRef, productServiceInputRef]);

  return (
    <>
      <form
        ref={searchbarRef}
        onKeyDown={handleKeyDown}
        onSubmit={handleSearch}
        // style={{border:!isMobileView&&"1px solid black",paddingLeft:"5px",paddingRight:"5px"}}
        className="grid gap-y-4 p-2 w-full bg-white  rounded lg:gap-x-2.5 lg:grid-cols-9 lg:mt-12 dark:bg-gray-800"
      >
        <div className="lg:col-span-2">
          <label htmlFor="location-form" className="sr-only ">
            Location
          </label>
          <div className="relative" onClick={handleClickSearchCity}>
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              ref={regionInputRef}
              onBlur={() => {
                if (!serachInputClick && textRef.current) {
                  focusNextInput(textRef);
                }
              }}
              value={searchInputResionValue}
              onChange={(e) => {
                handleSearchRegionInputChange(e);
                setTimeout(() => {});
              }}
              type="text"
              id="location-form"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Location"
            />
            {
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  left: 0,
                  zIndex: 1,
                  top: "100%",
                }}
              >
                {regionDropdownVisible && (
                  <div>
                    <SearchCityDropDown
                      searchTerm={searchInputResionValue}
                      handleClickOnRegion={handleClickOnRegion}
                    />
                  </div>
                )}
              </div>
            }
          </div>
        </div>

        <div className="lg:col-span-2">
          <select
            ref={textRef}
            value={selectedVendorType}
            onClick={() => handleClickSearchInput(selectedVendorType)}
            onFocus={() => {
              handleSearchInputChange({
                target: { value: selectedVendorType },
              });
            }}
            onChange={(e) => {
              handleSearchInputChange(e);
              if (e.target.value !== "Select Vendor Type") {
                focusNextInput(productServiceInputRef);
                setTimeout(() => {
                  setSearchInputProducts(true);
                });
              }
            }}
            id="guests"
            class={`bg-gray-50 border border-gray-300 text-gray-500 min-w-20 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pr-8 px-2.5 pl-2.5 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 ${
              vendorTypeError && !openDropdown && "border-red-500" // Add red border if vendor type is not selected
            }`}
          >
            <option>Select Vendor Type</option>
            {dropdownOptions?.map((data) => (
              <option
                value={data}
                // onClick={() => (data)}
              >
                {data}
              </option>
            ))}
          </select>
          {isMobileSearchBar && vendorTypeError && !selectedVendorType && (
            <div class="pl-2 text-red-600 text-xs mt-2">
              <span class="text-red-600">**</span>Please Select Vendor Type
            </div>
          )}
        </div>

        <div className="lg:col-span-3">
          <label htmlFor="location-form" className="sr-only">
            Search for Products/Services
          </label>
          <div className="relative">
            {!productServiceError && (
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
            <input
              ref={productServiceInputRef}
              type="text"
              id="products-services"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 ${
                productServiceError && !customProductService && "border-red-500" // Add red border if products/services field is not filled
              }`}
              placeholder={
                productServiceError
                  ? "Please enter the Product/service"
                  : "Search for Products/Services"
              }
              value={customProductService}
              onChange={(e) => {
                handleChangeProductAndServices(e);
              }}
              onClick={handleClickProductServices}
              list={`products-services-${selectedVendorType}`}
              // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            />
            {isMobileSearchBar &&
              productServiceError &&
              !customProductService && (
                <div class="pt-1 text-red-600 text-xs mt-2 ">
                  <span class="text-red-600">**</span>Please Enter
                  Products/Services
                </div>
              )}
            {searchInputProducts &&
              selectedVendorType !== "Select Vendor Type" &&
              openDropdown && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    left: 0,
                    zIndex: 1,
                    top: "100%",
                    marginTop: "5px",
                    backgroundColor: "white",
                    borderTop: "1px solid #C6C6C6",
                    borderRadius: "5px 5px 5px 5px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
                    maxHeight: "250px",
                    overflow: "auto",
                    paddingBottom: "8px",
                  }}
                >
                  <div
                    style={{ color: "#9AA0A6" }}
                    className="pl-4 py-1 text-xs"
                  >
                    Trending Searches
                  </div>

                  {vendorTypeOptions.map((option) => (
                    <div className="flex ml-4">
                      <svg
                        style={{ color: "#9AA0A6" }}
                        className=" w-4 h-4 text-sm"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                        />
                      </svg>
                      <div
                        onClick={() => {
                          handleChangeProductAndServices({
                            target: { value: option },
                          });
                        }}
                        key={option}
                        className="pl-2 text-xs text-gray-500 cursor-pointer py-1"
                      >
                        {option}
                      </div>
                    </div>
                  ))}
                </div>
              )}
          </div>
        </div>
        <button
          onClick={handleSearch}
          type="submit"
          style={{ transition: "box-shadow 0.3s", boxShadow: "none" }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow =
              "0px 0px 10px 0px rgba(0,0,0,0.7)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = "none";
          }}
          class="bg-[#FD6600] lg:col-span-2 justify-center md:w-auto text-white  focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm  py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 inline-flex items-center"
        >
          <svg
            className="mr-2 -ml-1 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
          Search
        </button>
      </form>
      {vendorTypeError && !openDropdown && (
        <div class="pt-1 text-white text-xs">
          <span class="text-white">**</span>Please Select Vendor Type
        </div>
      )}

      {productServiceError && !customProductService && (
        <div className="pt-1 text-white text-xs">
          <span className="text-white">**</span>Please Enter Products/Services
        </div>
      )}
    </>
  );
};

export default SearchBarNew;
