import React from 'react'
import { useNavigate } from 'react-router-dom'

const ExclusiveSupport = () => {
    const navigate = useNavigate()
    const handleDemoClick=()=>{
        navigate('/get-started')
        window.scroll(0,0)
    }

    return (
        <div className='bg-[#B0AAFF] bg-opacity-20 py-[80px] md:py-[100px] px-[16px] md:px-[26px] xl:p-[85px] '>
            <div className='max-w-[1024px] mx-auto'>
                <div className='bg-orange-300 w-[54px] h-2 md:w-[33px] xl:w-[56px] xl:h-[10px] mb-[30px]'></div>
                <div className='xl:flex'>
                    <div>
                        <h3 className='text-2xl md:text-[32px] xl:text-[40px] xl:leading-none font-bold'>Exclusive support services beyond software</h3>
                        <button onClick={handleDemoClick} className='text-base md:text-xl xs:text-lg font-bold pt-4 md:pt-8 text-[#F16500] flex items-center'>
                            Get a personalised demo
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_Arrow.svg" alt="->" className='h-3 ml-2 w-4 md:h-3 md:w-5 md:ml-4' />
                        </button>
                    </div>
                    <div className='space-y-[60px] xl:space-y-8 md:space-y-8 mt-[54px] md:mt-[99px] xl:mt-0 md:mx-12 xl:ml-auto xl:w-2/3'>
                        <div className='flex items-center'>
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/customer_support.svg" alt="" className='self-start' />
                            <div className='ml-[22px] md:ml-8 xl:ml-12'>
                                <p className='text-xl md:text-[24px] xl:text-[28px] font-bold pb-4 md:pb-2 xl:pb-4'>24/7 Customer Support</p>
                                <p className='text-lg md:text-xl text-[#474744]'>Connect with a real Neevay expert via email, chat, or phone support in less than a minute.</p>
                            </div>
                        </div>

                        {/* horizontal line */}
                        <hr className='hidden md:block' />

                        <div className='flex md:items-center'>
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/support_with_migrate.svg" alt="" className='self-start' />
                            <div className='ml-[22px] md:ml-8 xl:ml-12'>
                                <p className='text-xl md:text-[24px] xl:text-[28px] font-bold pb-4 md:pb-2  xl:pb-4'>Support with migrations</p>
                                <p className='text-lg md:text-xl text-[#474744]'>Your vendor data from other platforms to Neevay</p>
                            </div>
                        </div>

                        {/* horizontal line */}
                        <hr className='hidden md:block' />

                        <div className='flex md:items-center'>
                            <img src="https://storagereponeevaydevcdn.blob.core.windows.net/business/plane.svg" alt="" className='self-start' />
                            <div className='ml-[45px] md:ml-14 xl:ml-16'>
                                <p className='text-xl md:text-[24px] xl:text-[28px] font-bold pb-4 md:pb-2 xl:pb-4'>Tailored Onboarding & Training</p>
                                <p className='text-lg md:text-xl text-[#474744]'>Provide industry-specific tips & step-by-step software walkthroughs to help new customers maximize their use of Neevay.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExclusiveSupport
